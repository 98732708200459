import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const etudiantFacture = {
  state: {
    factures: [],
    facture: null
  },
  mutations: {
    setFacture(state, facture){
      state.facture = facture;
    },
    setFactures(state, factures) {
      state.factures = factures;
    }
  },
  actions: {
    async addFacture(context, formData){
      try {
        const response = await ApiService.postRequest('etudiant_document_factures', formData)
        context.commit("setFacture", response.data);

        Notify.create({
          message: "Facture ajoutée avec succès",
          color:'green'
        })
      }
      catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur est survenue lors de l'ajout d'une facture",
          color: 'red',
        });
      }
    },
    async editFacture(context, data)
    {
      try{
        const response = await ApiService.postRequest(data.url, data.formData);
        context.commit('setFacture', response.data)

        Notify.create({
          message: "Facture modifier avec succès",
          color: 'green',
        })
      }
     catch(error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification de la facture",
          color: 'red'
        })
     }
    }
  },
  getters: {
    getFacture(state){
      return state.facture;
    },
    getFactures(state){
      return state.factures;
    }
  }
}
