import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const dashboard = {
  state: {
    dashboards: [],
    dashboard: null,

    documentEtudiant: [],
    antennes: [],
    prestatairesDashboard: [],
    etudiantsDashboard: [],
    etudiantOpco: [],
    facturesEtudiantsDashboard: [],
    antenneConventions: [],
    facturesFiltre:[],
    opcoDossier: [],
    opcoStatus: [],
  },
  mutations: {
    setDashboards(state, dashboards){
      state.dashboards = dashboards;
    },
    setDashboard(state, dashboard){
      state.dashboard = dashboard;
    },
    setDocumentsEtudiant(state, documentEtudiant){
      state.documentEtudiant = documentEtudiant;
    },
    setAntennes(state, antennes){
      state.antennes = antennes;
    },
    setPrestatairesDashboard(state, prestatairesDashboard) {
      state.prestatairesDashboard = prestatairesDashboard;
    },
    setEtudiantsDashboard(state, etudiantsDashboard){
      state.etudiantsDashboard = etudiantsDashboard;
    },
    setEtudiantOpco(state, etudiantOpco){
      state.etudiantOpco = etudiantOpco;
    },
    setFacturesEtudiantsDashboard(state, facturesEtudiantsDashboard){
      state.facturesEtudiantsDashboard = facturesEtudiantsDashboard;
    },
    setAntennesConventionsDashboard(state, antenneConventions){
      state.antenneConventions = antenneConventions;
    },
    setFactureFiltre(state, factures){
      state.facturesFiltre = factures;
    },
    setOpcoDossier(state, opcoDossier){
      state.opcoDossier = opcoDossier;
    },
    setOpcoStatus(state, opcoStatus){
      state.opcoStatus = opcoStatus;
    }
  },
  actions: {
    async fetchDashboard(context){
      return await ApiService.getRequest('antennes_dashboard').then(
        response => {
          context.commit('setDashboards', response.data['hydra:member'])
        }
      ) .catch( error => { console.error(error) } )
    },

    // Récupere la liste des document du stagiaire validés
    async fetchEtudiantDocuments(context, url)
    {
      return await ApiService.getRequest(url).then(
        response => {
          context.commit('setDocumentsEtudiant', response.data['hydra:member'])
        }
      ) .catch( error => console.error(error))
    },

    // Récupere la liste des antennes
    async fetchAntennes(context){
      return await ApiService.getRequest('antennes').then(
        response => {
          context.commit('setAntennes', response.data['hydra:member'])
        }
      ) .catch(error => { console.error(error)})
    },

    //Récupère la liste des prestataires
    async fetchPrestataireDashboard(context){
      return await ApiService.getRequest('prestataires_dashboard').then(
        response => {
          context.commit("setPrestatairesDashboard", response.data['hydra:member']);
        }
      ) .catch(error => { console.error(error)})
    },

    // Récupère les stagaires
    async fetchEtudiantsDashboard(context, url)
    {
      return await ApiService.getRequest(url).then(
        response => {
          context.commit('setEtudiantsDashboard', response.data['hydra:member']);
        }
      ) .catch(error => { console.error(error)})
    },

    // Récupere La liste des stagiaire avec l'opco
    async fetchEtudiantOpco(context){
      return await ApiService.getRequest('student_opco_dashboard?type=1&etat=0').then(
        response => {
          context.commit('setEtudiantOpco', response.data['hydra:member']);
        }
      ) .catch(error => { console.error(error)})
    },

    //Récupération de la liste des dossier OPCO
    async fetchOpcoDossier(context, url){
      return await ApiService.getRequest(url).then(
        response => {
          context.commit('setOpcoDossier', response.data);
        }
      ).catch(error=> {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color: 'red'
        })
      })
    },

    //Récupération du status de l'opco
    async fetchOpcoStatus(context, url)
    {
      return await ApiService.getRequest(url).then(
        response => {
          context.commit('setOpcoStatus', response.data);
        }
      ).catch(error=> {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color: 'red'
        })
      })
    },
    //Récupère les facturation des aides des stagiaires
    async fetchFacturationStagiaire(context, data){
      return await ApiService.getRequest(`stagiaires_facturation?pagination=true&page=${parseInt(data.page)}`, data.filters).then(
        response=> {
          context.commit('setFacturesEtudiantsDashboard', response.data);
        }
      ).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color: 'red'
        });
      })
    },

    // Récupération des conventions
    async fetchAntenneConventionsDashboard(context){
      return await ApiService.getRequest('antenne_conventions?properties[]=id&properties[]=etat&properties[]=convention_number&etat=0').then(
        response => {
          context.commit('setAntennesConventionsDashboard', response.data['hydra:member']);
        }
      ).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color: 'red'
        });
      })
    },

    //Récuperation des Factures filtrés des etudiants
    async fetchFacturesFiltre(context, data){
      return await ApiService.getRequest(`etudiant_document_factures?pagination=true&page=${parseInt(data.page)}${data.makeQueryString}`, data.releaseDate).then(
        response => {
          context.commit('setFactureFiltre', response.data);
        }
      ).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color: 'red'
        });
      })
    },

    // Export Aides Mobilité
    async downloadFacturationsStagiaire(context, url){
      try{
        await ApiService.downloadRequest({url: url, method:'GET'});

      }catch(error){
        console.error(error);
        Notify.create({
          message: "Echec de l'éxport du fichier CSV.",
          color: 'red',
        });
      }
    },

  },
  getters:{
    getDashboards(state){
      return state.dashboards;
    },
    getDashboard(state){
      return state.dashboard;
    },
    getDocumentEtudiant(state){
      return state.documentEtudiant;
    },
    getAntennesDashboard(state){
      return state.antennes
    },
    getPrestatairesDashboard(state) {
      return state.prestatairesDashboard;
    },
    getEtudiantsDashboard(state){
      return state.etudiantsDashboard;
    },
    getEtudiantOpco(state){
      return state.etudiantOpco;
    },
    getFacturesEtudiantsDashboard(state){
      return state.facturesEtudiantsDashboard;
    },
    getAntenneConventionsntionsDashboard(state) {
      return state.antenneConventions;
    },
    getFacturesFiltre(state){
      return state.facturesFiltre;
    },
    getOpcoDossier(state){
      return state.opcoDossier;
    },
    getOpcoStatus(state){
      return state.opcoStatus
    }
  }
}
