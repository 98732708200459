import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const dossiers = {
  state: {
    dossiers: [],
    dossier: null,
  },
  mutations: {
    setDossier(state, dossier) {
      state.dossier = dossier;
    },
    setDossiers(state, dossiers){
      state.dossiers = dossiers
    }
  },
  actions: {
    async addDossier(context, data) {
      try{
        const response = await ApiService.postRequest(data.url, data.formData);
        context.commit('setDossier', response.data);

        Notify.create({
          message: "Dossier ajouté avec succès",
          color:'green'
        })
      }
      catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite à l'ajout du dossier",
          color: 'red'
        })
      }
    },
    async editDossier(context, data){
      try{
        const response = await ApiService.postRequest(data.url, data.formData);
        context.commit('setDossier', response.data);

        Notify.create({
          message: "Dossier modifié avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification du dossier",
          color:'red'
        })
      }
    }
  },
  getters: {
    getDossier(state){
      return state.dossier
    },
    getDossiers(state){
      return state.dossiers
    }
  }
}
