import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const etudiant = {
  state: {
    etudiants: [],
    etudiant: null
  },
  mutations: {
    setEtudiants(state, etudiants) {
      state.etudiants = etudiants
    },
    setEtudiant(state, etudiant) {
      state.etudiant = etudiant
    }
  },
  actions: {
    /* ETUDIANTS (GET) */
    async fecthEtudiant(context, idStagiaire) {
      try {
        return await ApiService.getRequest(`/etudiants/${idStagiaire}`).then(
          response => {
            this.commit('setEtudiant', response.data);
            return response.data;
          });
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    fetchEtudiants(context, filters) {
      if (filters !== undefined) {
        return ApiService.getRequest('/stagaires_pedagogie_light_list&' + filters).then(
          response => {
            context.commit('setEtudiants', response.data['hydra:member'])
          }
        )
          .catch( error => { console.error(error) } )
      } else {
        return ApiService.getRequest('/stagaires_pedagogie_light_list').then(
          response => {
            context.commit('setEtudiants', response.data['hydra:member'])
          }
        )
          .catch( error => { console.error(error) } )
      }
    },
    fetchEtudiantsByFilter(context, filters) {
      //todo:mettre la possibilité d'avoir plusieurs filtres
      //si ${filters} retourne [object, object]
      return ApiService.getRequest(`/stagaires_pedagogie_light_list?${Object.keys(filters)}=${Object.values(filters)}`)
        .then(response => {
          context.commit('setEtudiants', response.data['hydra:member']);
          return response.data['hydra:member'];
        })
        .catch( error => { console.error(error) } )
    },
    async fetchPifEtudiantById(context, idEtudiant) {
      return new Promise((resolve, reject) => {
        ApiService.getRequest(`/stagiaires_plan_individuel/${idEtudiant}`)
          .then(response => {
            context.commit('setEtudiant', response.data);
            resolve();
          })
          .catch(error => {
            console.error(error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          })
      });
    },
    async fetchEvaluationEtudiantById(context, idEtudiant) {
      return new Promise((resolve, reject) => {
        ApiService.getRequest(`/stagiaires_evaluations/${idEtudiant}`)
          .then(response => {
            context.commit('setEtudiant', response.data);
            resolve();
          })
          .catch(error => {
            console.error(error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          })
      });
    },
    // Récupére les étudiants en fonction de leur date d'entree
    async fetchEtudiantByDateEntree(context, data){
      return await ApiService.getRequest(data.url, data.dateEntree).then(
        response => {
          context.commit('setEtudiants', response.data['hydra:member']);
        }
      ) .catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color: "red"
        });
      })
    },
    /* CRUD ETUDIANTS (POST/PATCH/DELETE) */
    async editEtudiant({ state }, formData) {
      try {
        const response = await ApiService.patchRequest(`${formData.uri}`, formData);
        if (response.status === 200) {
          this.commit('setEtudiant', response.data);
          Notify.create({
            message: "La convention de l'étudiant a été modifiée avec succès",
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // Modifie le stagiaire de façon à le retirer de la session
    async editEtudiantSession(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, {diplome_session: data.diplome_session} );
        context.commit('setEtudiant', response.data);

        Notify.create({
          message: "Le stagiaire a bien été retiré de la session",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification",
          color: 'red'
        })
      }
    },
    /*Modifie l'étudiant lorsqu'il passe en état de sortie,
      + lors de la modification d'un contrat en apprentissage
      + Bascule nouveau parcours
    */
    async updateEtudiantStatus(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.datas);
        context.commit('setEtudiant', response.data);
        Notify.create({
          message: "L'étudiant.e a été mofié.e avec succès",
          color: 'green'
        });
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification de l'étudiant.e",
          color: 'red'
        })
      }
    },
    async updateBasculeEtudiant(context, data){
      try{
        const response = await ApiService.postRequest(data.url, data.stagiaire);
        context.commit('setEtudiant', response.data);
        Notify.create({
          message: "L'étudiant.e a bien été basculé.e",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification de l'étudiant.e",
          color: 'red'
        })
      }
    },
    // Export CSV
    async exportEtudiantCSV(context, url){
      try{
        await ApiService.downloadRequest({url: url, method: 'GET'});
      }catch(error){
        console.error(error);
        Notify.create({
          message: "une erreur s'est produite lors de l'export des données",
          color: 'red'
        })
      }

    }
  },
  getters: {
    getEtudiants(state) {
      return state.etudiants;
    },
    getEtudiant(state) {
      return state.etudiant;
    }
  }
}
