import {boot} from 'quasar/wrappers'
import ApiService from '@services/api.service'
import AuthService from '@services/auth.service'
import moment from 'moment'

require('moment/locale/fr')


const paginationKeyMapping = {
    '@id': 'current',
    'hydra:first': 'first',
    'hydra:prev': 'prev',
    'hydra:next': 'next',
    'hydra:last': 'last'
}

function shuffle(array, seed) {
    let currentIndex = array.length, temporaryValue, randomIndex;
    seed = seed || 1;
    let random = function () {
        var x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    };
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}

function getHash(input) {
    var hash = 0, len = input.length;
    for (var i = 0; i < len; i++) {
        hash = ((hash << 5) - hash) + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
    }
    return hash;
}

// * Utilisé pour Tableau de bord, onglet Antenne
function parseQueryString(urlString) {
    let a = document.createElement("a")
    a.href = urlString
    let fullPath = a.href

    let url = new URL(fullPath)
    return Object.fromEntries(url.searchParams)
}

export function $parsePaginationData(response) {
    let responseData = (response && response.data) || response

    if (!responseData) {
        throw 'Could\'nt parse pagination data from given parameters'
    }

    let apiPlatformPaginationData = responseData["hydra:view"]
    if (!apiPlatformPaginationData || typeof apiPlatformPaginationData != 'object' || !('@id' in apiPlatformPaginationData)) {
        throw "Couldn't find proper pagination data at property key 'hydra:view'"
    }

    let paginationData = {
        current: {page: 1, url: apiPlatformPaginationData["@id"]},
        first: {page: 1, url: apiPlatformPaginationData["@id"]},
        last: {page: 1, url: apiPlatformPaginationData["@id"]}
    }

    if (Object.keys(apiPlatformPaginationData).length <= 2) {
        return paginationData
    }

    for (const [apiPlatformKey, apiPlatformValue] of Object.entries(apiPlatformPaginationData)) {

        if (apiPlatformKey in paginationKeyMapping) {
            let url = apiPlatformValue
            let newKey = paginationKeyMapping[apiPlatformKey]
            let queryParams = parseQueryString(url)

            if ((!queryParams || !queryParams.page || queryParams.page.length == 0)) {
                throw `Couldn't find 'page' parameters while parsing query string from url '${url}'`
            }

            paginationData[newKey] = {
                page: parseInt(queryParams.page),
                url: url
            }
        }
    }

    return paginationData
}

// * Utilisé pour filtrer les documents dans le Tableau de bord onglet Antenne
function $makeParamArray(key, arr) {
    return arr.map(val => `${key}[]=${val}`).join('&');
}

export function $queryStringMaker(object, filtersToExclude, keyPrefix = "") {
    if (typeof filtersToExclude === 'undefined') {
        filtersToExclude = []
    }
    // console.log(object)
    return Object.keys(object)
        .map(key => {
            if (!filtersToExclude.includes(key)) {
                if (Array.isArray(object[key])) {
                    return $makeParamArray(key, object[key])
                }

                if (typeof object[key] === "object" && object[key]) {
                    return $queryStringMaker(object[key], [], keyPrefix + key + '.')
                }
                return object[key] !== null && object[key] !== '' ? `${keyPrefix + key}=${object[key]}` : null
            }
        })
        .filter(n => n)
        .join('&')
}


export default boot(async ({app}) => {
    app.mixin({
        methods: {
            $isPersisted: function (data) {
                if (typeof data !== "object") {
                    throw `Parameter should be an object, ${typeof data} given`
                }
                return data && ((typeof data["@id"] !== "undefined" && data["@id"].length > 0) || (typeof data.id !== "undefined" && data.id.length > 0))
            },
            $getStoreItem: function (storeValues, id) {
                if (!Array.isArray(storeValues)) {
                    throw new Error(`Store values should be an 'array', '${typeof storeValues}' given`);
                }

                // Retourne null directement dans le cas ou id est null ou undefined, et évite les warning inutiles
                if (id == null) {
                  return null;
                }

                const foundItem = storeValues.find(object => object.id === id);

                if (!foundItem) {
                    console.warn(`Could not find any item with an id of '${id}' in store values`, storeValues);
                }

                return foundItem || null;
            },
            $getStoreLabel: function (storeValues, id, options) {
                options = options || {}

                //  transforme un id undefined en null si attribut inexistant
                if (id === undefined) {
                    id = null;
                }

                let defaultLabel = options.default || ""
                let valueKey = options.valueKey || 'name'

                const foundItem = this.$getStoreItem(storeValues, id)

                if (!foundItem) {
                    return defaultLabel
                }

                if (!(valueKey in foundItem)) {
                    throw `Could not get value for store item#${id} because found item does not have a '${valueKey}' property`
                }

                return foundItem[valueKey] || defaultLabel
            },
            $getIRIFromProperty: function (property) {
                return (property && property["@id"]) ||
                    (typeof property === "string" && property.length > 0 && property) ||
                    null
            },
            $hasRole: function (role) {
                return AuthService.checkRole(role)
            },
            $displaySuccess: function (successMessage) {
                this.$q.notify({
                    group: 'notification',
                    type: 'positive',
                    message: successMessage || 'La donnée a bien été mise à jour'
                })
            },
            $displayError: function (errorMessage) {
                this.$q.notify({
                    group: 'notification',
                    type: 'negative',
                    message: errorMessage || 'Une erreur est survenue'
                })
            },
            $parsePaginationData: function (response) {
                let responseData = (response && response.data) || response

                if (!responseData) {
                    throw 'Could\'nt parse pagination data from given parameters'
                }

                let apiPlatformPaginationData = responseData["hydra:view"]
                if (!apiPlatformPaginationData || typeof apiPlatformPaginationData != 'object' || !('@id' in apiPlatformPaginationData)) {
                    throw "Couldn't find proper pagination data at property key 'hydra:view'"
                }

                let paginationData = {
                    current: {page: 1, url: apiPlatformPaginationData["@id"]},
                    first: {page: 1, url: apiPlatformPaginationData["@id"]},
                    last: {page: 1, url: apiPlatformPaginationData["@id"]}
                }

                if (Object.keys(apiPlatformPaginationData).length <= 2) {
                    return paginationData
                }

                for (const [apiPlatformKey, apiPlatformValue] of Object.entries(apiPlatformPaginationData)) {

                    if (apiPlatformKey in paginationKeyMapping) {
                        let url = apiPlatformValue
                        let newKey = paginationKeyMapping[apiPlatformKey]
                        let queryParams = this.$parseQueryString(url)

                        if ((!queryParams || !queryParams.page || queryParams.page.length == 0)) {
                            throw `Couldn't find 'page' parameters while parsing query string from url '${url}'`
                        }

                        paginationData[newKey] = {
                            page: parseInt(queryParams.page),
                            url: url
                        }
                    }
                }

                return paginationData
            },
            $parseQueryString: function (urlString) {
                let a = document.createElement("a")
                a.href = urlString
                let fullPath = a.href

                let url = new URL(fullPath)
                return Object.fromEntries(url.searchParams)
            },
            $parseDate: function (date, options = {}) {
                let format = options.format || "DD/MM/YYYY"
                let defaultValue = options.default || ""

                let momentDate = moment(date || null) // Cast undefined to null since moment(undefined) == moment()
                return momentDate.isValid() ? momentDate.format(format) : defaultValue
            },
            $parseDateFrToDate: function (dateFr) {
                let date = new Date(dateFr);

                if (this.$isValidDate(date) === false) {
                    date = new Date(dateFr.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
                }

                return moment(date);
            },
            $isValidDate(date) {
                return date instanceof Date && !isNaN(date);
            },
            $parseMoney: function (value) {
                if (!value) {
                    return 0
                }

                if (isNaN(value)) {
                    throw `Could not parse value '${value}' of type ${typeof value} to money type, expected number`
                }

                if (Math.floor(value) === value) {
                    return value.toString()
                }

                let decimals = value.toString().split(".")[1].length || 0;
                if (decimals == 0) {
                    return value.toString()
                } else if (decimals == 1) {
                    return value.toString() + "0"
                } else {
                    return Math.round(value * 100) / 100
                }
            },
            $parsePhoneNumber: function (phoneNumber) {
                if (!phoneNumber) {
                    return '';
                }

                if (typeof phoneNumber !== 'string') {
                    throw new Error(`Expected a string but received a ${typeof phoneNumber}`);
                }

                let cleaned = phoneNumber.replace(/\D/g, '');

                let match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);

                if (match) {
                    return `${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
                }

                return phoneNumber;
            },
            $parseSirenOrSiretNumber: function (str) {
                if (!str) {
                    return '';
                }

                if (typeof str !== 'string') {
                    throw new Error(`Expected a string but received a ${typeof str}`);
                }

                let cleaned = str.replace(/\D/g, '');

                if (str.length === 9) {
                    let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
                    if (match) {
                        return `${match[1]} ${match[2]} ${match[3]}`;
                    }
                } else if (str.length === 14) {
                    let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{5})$/);
                    if (match) {
                        return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
                    }
                }

                return str;
            },
            $parseSocialSecurityNumber: function (socialSecurityNumber) {
                if (!socialSecurityNumber) {
                    return '';
                }

                if (isNaN(socialSecurityNumber)) {
                    throw `Could not parse value '${socialSecurityNumber}' of type ${typeof socialSecurityNumber} to social number type, expected number`;
                }

                let cleaned = ('' + socialSecurityNumber).replace(/\D/g, '');

                const match = cleaned.match(/^(\d)(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})$/);

                if (match) {
                    return `${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]} ${match[6]} ${match[7]}`;
                }

                return socialSecurityNumber;
            },
            /**
             * Returns a color from a randomly generated (from seed) color list
             * Purpose it to always retrieve the same color from the same input value
             * Works great with integers as 'value' atm
             */
            $generateAssociatedColor: function (value, options) {
                options = options || {}
                let colors = ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'cyan', 'teal', 'green', 'light-green', 'orange', 'deep-orange', 'brown', 'grey', 'blue-grey']

                let seed = parseInt(options.paletteSeed || 0)
                let colorCount = parseInt(options.colorCount || colors.length)
                let variant = parseInt(options.variant)

                if (isNaN(seed) || isNaN(colorCount) || colorCount > colors.length) {
                    throw `Couldn't parse parameters correctly`
                }
                let shuffledColors = shuffle(colors, seed).slice(0, colorCount)
                let valueAsNumber = parseFloat(value)
                let valueAsInt = (!isNaN(valueAsNumber) && valueAsNumber.toString() == value) ? parseInt(valueAsNumber) : getHash(value || "")
                let associatedKey = ((valueAsInt % colorCount) + colorCount) % colorCount // Normal modulo can give negative results

                return isNaN(variant) ? shuffledColors[associatedKey] : shuffledColors[associatedKey] + "-" + variant
            },
            // TODO:
            //  - Generated computed property cannot be used as a reactive variable
            //  - Fix calls when calling from JSON.Stringify() on parent object even if the property will not be used
            $addComputedFetchIRIProperty: function (parentObject, IRIPropertyName, fetchBaseUrl) {
                let vue_component = this
                let _foundPropertyName = `_found_${IRIPropertyName}`
                parentObject[`${IRIPropertyName}_`] = () => null
                Object.defineProperty(parentObject, `${IRIPropertyName}_`, {
                    get: function () {
                        if (typeof this[_foundPropertyName] === "undefined" || (this[_foundPropertyName] && this[_foundPropertyName]["@id"] !== this[IRIPropertyName])) {
                            // Permet d'éviter de charger plusieurs fois la même ressource
                            this[_foundPropertyName] = null

                            ApiService.fetchObjectFromIRI(this[IRIPropertyName], fetchBaseUrl).then(object => this[_foundPropertyName] = object)
                        }
                        return this[_foundPropertyName]
                    },
                    set: () => {
                    }
                })
            },
            $makeParamArray: function (key, arr) {
                return arr.map(val => `${key}[]=${val}`).join('&');
            },
            $queryStringMaker: function (object, filtersToExclude, keyPrefix = '') {
                if (typeof filtersToExclude === 'undefined') {
                    filtersToExclude = []
                }
                return Object.keys(object)
                    .map(key => {
                        if (!filtersToExclude.includes(key)) {
                            if (Array.isArray(object[key])) {
                                return this.$makeParamArray(key, object[key])
                            }

                            if (typeof object[key] === "object" && object[key]) {
                                return this.$queryStringMaker(object[key], [], keyPrefix + key + '.')
                            }
                            return object[key] !== null && object[key] !== '' ? `${keyPrefix + key}=${object[key]}` : null
                        }
                    })
                    .filter(n => n)
                    .join('&')
            },
          $deepMergeObjectsWithCollections: function (target, source) {
            function isArray(value) {
              return Array.isArray(value);
            }

            Object.keys(source).forEach((key) => {
              if (source[key] && typeof source[key] === 'object' && !isArray(source[key])) {
                if (!target[key] || Object.values(source[key]).length === 0) {
                  Object.assign(target, { [key]: {} });
                }
                this.$deepMergeObjectsWithCollections(target[key], source[key]);
              } else {
                Object.assign(target, { [key]: source[key] });
              }
            });
          },
          $softMergeObjectsWithoutCollection: function (target, source) {
            function isPrimitive(value) {
              return value !== Object(value);
            }

            Object.keys(source).forEach((key) => {
              if (source[key] && isPrimitive(source[key])) {
                target[key] = source[key];
              }
            });
          },
            // * Transforme le format de la date
            $formatDateFr: function (date) {
                if (!date) {
                    return ''
                }
                return moment(date).format('DD/MM/YYYY')
            },
            $capitalizeFirstLetter(str) {
                return str[0].toUpperCase() + str.slice(1);
            }
        }
    })
})
