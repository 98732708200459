import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const etudiantAbsences = {
  state: {
    etudiantAbsences: [],
    etudiantAbsence: null
  },
  mutations: {
    setEtudiantAbsences(state, absences) {
      state.etudiantAbsences = absences;
    },
    setEtudiantAbsence(state, absence) {
      state.etudiantAbsence = absence;
    }
  },
  actions: {
    fetchAbsencesByStudent(context, idStudent) {
      return ApiService.getRequest(`/etudiant_absences?etudiant=${idStudent}`).then(
        response => {
          this.commit('setEtudiantAbsences', response.data['hydra:member']);
          return response.data['hydra:member'];
        })
        .catch(error => { console.error(error) });
    },
    //  paramètres obligatoires : etudiantId (Number) / dt_start (format: YYYY-MM-DD) / dt_end (format: YYYY-MM-DD) / hr_start (format: HH:mm:ss) / hr_end (format: HH:mm:ss)
    fetchAbsenceByStudentAndDates(context, data) {
      let properties = ['etudiantId', 'dt_start', 'dt_end', 'hr_start', 'hr_end'];
      let isValid = false;
      if (data && properties.every(propName => data[propName] !== null && data[propName] !== undefined)) {
        isValid = true;
      }

      if (isValid === true) {
        let uri = '/etudiant_absences?';
        uri += `etudiant.id=${data.etudiantId}`;
        uri += `&dt_start=${data.dt_start}`;
        uri += `&dt_end=${data.dt_end}`;
        uri += `&hr_start=${data.hr_start}`;
        uri += `&hr_end=${data.hr_end}`;
        return ApiService.getRequest(uri)
          .then(
            response => {
              this.commit('setEtudiantAbsence', response.data['hydra:member'][0]);
              return response.data['hydra:member'][0];
            })
          .catch(error => { console.error(error) });
      } else {
        //  paramètres obligatoires : etudiantId (Number) / dt_start (format: YYYY-MM-DD) / dt_end (format: YYYY-MM-DD) / hr_start (format: HH:mm:ss) / hr_end (format: HH:mm:ss)
        throw new Error('Invalid parameters.');
      }

    },
    //  ajout d'une absence à un stagiaire
    async addEtudiantAbsence({ state, dispatch }, data) {
      try {
        const response = await ApiService.postRequest('/etudiant_add_absence', data);
        if (response.data) {
          let form = new FormData();
          form.append('etudiant', data.etudiant);
          form.append('raison', data.raison);
          form.append('dt_start', data.dt_start);
          form.append('dt_end', data.dt_end);
          form.append('hr_start', data.hr_start);
          form.append('hr_end', data.hr_end);
          form.append('etudiant_id', data.etudiant_id);
          form.append('absence_id', response.data.id);
          form.append('antenne_id', data.antenne);

          let dataAbsence = {
            form: form,
            etudiant_id: data.etudiant_id
          }
          dispatch('addEtudiantPointageAbsence', dataAbsence);
          Notify.create({
            message: "L'absence a été créée avec succès.",
            color: "green"
          });
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // Ajoute l'abscence d'un stagiaire
    async addAbsence(context, formData){
      try{
        const response = await ApiService.postRequest('etudiant_absences', formData);
        context.commit('setEtudiantAbsence', response.data);

        Notify.create({
          message: "Abscence ajoutée avec succès",
          color: "green"
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout de l'absence",
          color: 'red'
        })
      }
    },
    //Modifie l'absence séléctionnée
    async updateAbsence(context, data){
      try{
        const response = await ApiService.postRequest(data.url, data.formData);
        context.commit('setEtudiantAbsence', response.data);

        Notify.create({
          message: "L'absence à été modifiée avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          messsage: "Un erreur s'est produite lors de la modification de l'absence",
          color: 'red'
        })
      }
    }
    //todo: delete absence
  },
  getters: {
    getEtudiantAbsences(state) {
      return state.etudiantAbsences;
    },
    getEtudiantAbsence(state) {
      return state.etudiantAbsence;
    }
  }
}
