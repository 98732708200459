import ApiService from "@services/api.service";
import {Notify} from 'quasar';
export const etudiantSessions = {
  state: {
    sessions: [],
    session: null,
  },
  mutations: {
    setSessions(state, sessions){
      state.sessions = sessions;
    },
    setSession(state, session){
      state.session = session;
    },
  },
  actions: {
    /*Récupère l'ensemble des stagiaires en fonction de leur diplômes et de de l'antenne
      Pour les sessions des diplômes ou des actions de formations
     */
    async fetchEtudiantSessions(context, data){
      return await ApiService.getRequest('/session_stagaires_list', {diplome: data.diplome, antenne: data.antenne}).then(
        response => {
          context.commit('setSessions', response.data['hydra:member']);
        }
      ).catch(error => {
        console.error(error);
        Notify.create({
          messsage: "Une erreur s'est produite lors de la récupération des stagiaires",
          color: 'red'
        })
      })
    },
    /*
      Ajoute une session pour la gestion des groupes
    */
    async addSessionEtudiant(context, formData){
      try{
        const response = await ApiService.postRequest('antenne_groupe_sessions', formData);
        context.commit('setSession', response.data);
        Notify.create({
          message: `${response.data.name} a été ajoutée avec succès`,
          color: 'green'
        })
      }catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout de la session",
          color: 'red'
        })
      }
    },
    //Modifie une session de la gestion des groupes
    async updateSession(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.formData);
        context.commit('setSession', response.data);

        if(response.status === 200 ){
          Notify.create({
            message: `${response.data.name} modifiée avec succès`,
            color: 'green'
          })
        }
      }catch (error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produitelors de la modifiaction de la session",
          color: 'red'
        })
      }
    },
    // Ajoute la liste des stagiaires par rapport à un groupe séléctionné
    async updateSessionListEtudiant(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.groupe);
        context.commit('setSession', response.data);

        if(response.status === 200){
          Notify.create({
            message: 'Session modifiée avec succès',
            color: 'green'
          })
        }
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification",
          color: 'red'
        })
      }
    },
    //Ajoute les stagiaires en les transferant un à un
    async updateSessionEtudiant(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.stagiaire);
        context.commit('setSession', response.data);

        if(response.status === 200){
          Notify.create({
            message: 'Session modifiée avec succès',
            color: 'green'
          })
        }
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification",
          color: 'red'
        })
      }
    },
    //TRANSFERT D'UN STAGIAIRE D'UNE SESSION À L'AUTRE
    async transfereEtudiantSession(context, data){
      try{
        const response = await ApiService.postRequest(data.url, data.formData);
        context.commit('setSession', response.data);

        if(response.status === 201){
          Notify.create({
            message: "l'étudiant.e a été tranféré.e avec succès",
            color: 'green'
          })
        }
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors du transfere du stagiaire",
          color: 'red'
        })
      }
    }
  },
  getters: {
    getSessions(state){
      return state.sessions;
    },
    getSession(state){
      return state.session;
    }
  }
}
