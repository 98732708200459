import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const assistance = {
  state: {
    assistance: null,
    assistances: [],
  },
  mutations: {
    setAssistances(state, assistances){
      state.assistances = assistances;
    },
  },
  actions: {
    async sendAssistance(context, formData){
      try{
        let response = await ApiService.postRequest('/send_ticket', formData);
        context.commit('setAssistances', response.data);

        Notify.create({
          message:"Votre demande a bien été envoyée",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur est survenue",
          color: 'red'
        })
      }
    }
  },
  getters: {
    getAssistences(state){
      return state.assistances;
    }
  }
}
