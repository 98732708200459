import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const etudiantPointages = {
  state: {
    pointages: [],
    pointage: null
  },
  mutations: {
    setPointages(state, pointages) {
      state.pointages = pointages;
    },
    setPointage(state, pointage) {
      state.pointage = pointage;
    }
  },
  actions: {
    fetchPointagesByAbsenceId(context, uri_absence) {
      return ApiService.getRequest(`pointages_light?absence=${uri_absence}`).then(
        response => {
          this.commit('setPointages', response.data['hydra:member'])
          return response.data['hydra:member'];
        }
      ).catch(error => { console.error(error) });
    },
    // Récupération du pointage
    fetchPointagesEtudiant(context, data){
      return ApiService.getRequest('/pointage_occurences_etudiant/', {start: data.start, end: data.end, etudiant_id: data.etudiant_id}).then(
        response => {
          context.commit('setPointages', response.data);
        }
      ).catch(error => { console.error(error) });
    },
    //  ajout d'une absence à un pointage
    async addEtudiantPointageAbsence({ state, dispatch }, data) {
      try {
        const etudiant = await dispatch('fecthEtudiant', data.etudiant_id);
        if (
          (
            etudiant.date_sortie !== null &&
            etudiant.date_sortie !== undefined
          ) ||
          (
            etudiant.date_sortie_prevue !== null &&
            etudiant.date_sortie_prevue !== undefined
          )
        ) {

          const response = ApiService.postRequest('/etudiant_pointage_add_absence', data.form);
          if (response) {
            Notify.create({
              message: "L'absence a été créée avec succès.",
              color: "green"
            });
            return Promise.resolve();
          }
        }
      } catch (error) {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //Ajoute une absence à un pointage après avoir crée l'absence dans etudiant_absences
    async addAbsencePointage(context, formData){
      try{
        await ApiService.postRequest('/etudiant_pointage_add_absence', formData);
        Notify.create({
          message: "L'abcence à été ajoutée au pointage",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: 'red'
        });
      }
    },
    /*Modifie le pointage pour le passage en sortie d'un stagiaire + rupture d'un contrat*/
    async updatePointageStop(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.datas);
        context.commit("setPointage", response.data);

        Notify.create({
          message: "Mise à jour du pointage de l\'etudiant !",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modificatin du pointage",
          color: 'red'
        })
      }
    },

    deleteEtudiantPointageAbsence({ state }, formData) {
      try {
        ApiService.postRequest('/etudiant_pointage_delete_absence', formData);
        Notify.create({
          message: "La suppression du pointage a été effectuée avec succès.",
          color: "green"
        });
        return Promise.resolve();
      } catch (error) {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getPointages(state) {
      return state.pointages;
    },
    getPointage(state) {
      return state.pointage;
    }
  }
}
