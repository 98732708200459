import ApiService from "@services/api.service";
import { Notify } from 'quasar';

export const antenneActions = {
  state: {
    actions: [],
    action: null,
    session: null
  },
  mutations: {
    setActions(state, actions)
    {
      state.actions = actions;
    },
    setAction(state, action)
    {
      state.action = action
    },
    setSessionAction(state, session){
      state.session = session;
    }
  },
  actions: {
    fetchActionsFormation(context)
    {
      return ApiService.getRequest('antenne_actions', {etat: 0}).then(
        response => {
          context.commit('setActions', response.data['hydra:member']);
        }
      )
      .catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récuperation des données",
          color: 'red'
        })
      })
    },
    fetchActionsFormationById(context, actionId)
    {
      return ApiService.getRequest(`${actionId}`).then(
        response => {
          context.commit('setAction', response.data);
        }
      )
        .catch(error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est prouite lors de la récupération des données",
            color: 'red'
          })
        })
    },
    // Récupère les session des actions de l'antenne
    fetchAntenneActions(context, params){
      return ApiService.getRequest('/antenne_action_sessions?properties[]=id&properties[]=dropdown_label&properties[]=etat', params).then(
        response => {
          context.commit('setActions', response.data['hydra:member']);
        }
      )
    },
    // Récupere les sessions des actions de formation
    async fetchSessionsActions(context, data){
      return ApiService.getRequest('antenne_action_sessions', {action: data.action, antenne: data.antenne}).then(
        response => {
           context.commit('setAction', response.data['hydra:member']);
        }
      ).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est prouite lors de la récupération des données",
          color: 'red'
        })
      })
    },
    addAction(context, formData)
    {
      try{
        const response = ApiService.postRequest('antenne_actions', formData)
        context.commit('setAction', response.data);
        Notify.create({
          messsage: "L'action a été ajoutée avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite a l'ajout de l'action de formation",
          color: 'red'
        })
      }
    },
    async editAction(context, data){
      try{
        const response = await ApiService.postRequest(data.url, data.formData);
        context.commit('setAction', response.data);

        Notify.create({
          messsage: "L'action a été modifiée avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modificationde l'action de formation",
          color: 'red'
        })
      }
    },
    //supprime une action de formation non diplômante ou un document
    async archiveAction(context, data) {
      try {
        const response = await ApiService.postRequest(data.url, data.formData);

        if (response.status === 201) {
          context.commit('setAction', response.data);
          Notify.create({
            message: "Suppression terminée",
            color: "green"
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      }
    },
    //Ajoute une session a une action
    async addSessionAction(context, formData){
      try{
        const response = await ApiService.postRequest('antenne_action_sessions', formData);
        context.commit('setSessionAction', response.data);
        Notify.create({
          message: 'La session a bien été ajoutée',
          color: 'green'
        });
      }catch(error){
        console.log(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout de la session",
          color: 'red'
        })
      }
    },
    async editSessionAction(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.formData);
        context.commit('setActions', response.data);

        Notify.create({
          message: "La session a été modifiée avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification de la session",
          color: 'red'
        });
      }
    },
  },
  getters: {
    getActions(state)
    {
      return state.actions;
    },
    getAction(state)
    {
      return state.action;
    },
    getSessionAction(state){
      return state.session;
    }
  }
}
