import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antennesFermeture = {
  state: {
    fermetures: [],
    fermeture: null,
  },
  mutations: {
    setFermetures(state, fermetures){
      state.fermetures = fermetures
    },
    setFermeture(state, fermeture){
      state.fermeture = fermeture;
    },
  },
  actions: {
    fetchFermetures(context, antenne)
    {
      return ApiService.getRequest('antenne_fermetures', {antenne: antenne}).then(
        response => {
          context.commit("setFermetures", response.data['hydra:member']);
        }
      ).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur est survenue lors de la récupération des données",
          color: 'red'
        })
      })
    },
    //Ajoute la liste des dates de fermeture par défaut
    async addAntenneFermetures(context, formData){
      try{
        const response = await ApiService.postRequest('antenne_fermetures', formData);
        if(response.data.closing !== null)
        {
          context.commit('setFermetures', response.data.closing);
        }
        else{
          Notify.create({
            message: "La donnée n'a pas pu être ajoutée",
            color: 'red',
          })
        }

        Notify.create({
          message: "Les périodes de fermeture ont été ajoutées avec succès",
          color: 'green'
        })
      }catch(errror){
        console.error(errror);
        Notify.create({
          message: "Une erreur est survenue lors de l'ajout des périodes de fermeture",
          color: 'red',
        })
      }
    },
    //Supprime la période de fermeture
    async removeFermeture(context, idFermeture)
    {
      try{
        ApiService.deleteRequest('antenne_fermetures/' + idFermeture);

        Notify.create({
          message: "La période de fermeture à été supprimée avec succès",
          color: 'green'
        })
      }catch(error){
        console.log(error)
        Notify.create({
          message: "Une erreur s'est produite lors de la suppression de la période de fermeture",
          color: 'red'
        })
      }
    }
  },
  getters: {
    getFermetures(state){
      return state.fermetures;
    },
    getFermeture(state){
      return state.fermeture;
    }
  }
}
