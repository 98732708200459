import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antenneDiplomeSessions = {
  state: {
    sessions: [],
    session: null,
    objet: null,

    delegue: null,
    delegues: [],
    etudiantsDiplomeSession: [],
  },
  mutations: {
    setAntenneDiplomeSessions(state, sessions) {
      state.sessions = sessions
    },
    setAntenneDiplomeSession(state, session) {
      state.session = session
    },
    setDelegue(state, delegue){
      state.delegue = delegue;
    },
    setDelegues(state, delegues){
      state.delegues = delegues;
    },
    setEtudiantsDiplomeSession(state, etudiantsDiplomeSession){
      state.etudiantsDiplomeSession = etudiantsDiplomeSession;
    }
  },
  actions: {
    fetchAntenneDiplomeSessions(context) {
      //  * liste des référentiels filtrés en amont par doctrine => le cfa voit les sessions de ses antennes
      return ApiService.getRequest('diplome_sessions').then(
        response => {
          context.commit('setAntenneDiplomeSessions', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error)
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    // Récupére les diplomes et leurs sessions de l'antenne
    fetchAntenneDiplomesSessions(context, params){
      return ApiService.getRequest('/antenne_diplome_sessions?properties[]=id&properties[]=dropdown_label&properties[]=etat', params).then(
        response => {
          context.commit('setAntenneDiplomeSessions', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color: "red"
        })
      });
    },
    fetchAntenneActiveDiplomeSessions(context) {
      //  * liste des référentiels filtrés en amont par doctrine => le cfa ne voit que ses sessions
      return ApiService.getRequest('antenne_diplomes_sessions?etat=0').then(
        response => {
          context.commit('setAntenneDiplomeSessions', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error)
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    //Recupére les sessions en fonction de l'antenne et du diplome
    async fetchSessions(context, data){
      return ApiService.getRequest('antenne_diplome_sessions', {diplome: data.diplome, antenne: data.antenne}).then(
        response => {
          context.commit('setAntenneDiplomeSessions', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      })
    },
    // Récupére la session selon le diplome selection
    async fetchSessionsByDiplome(context, data)
    {
      return await ApiService.getRequest('/antenne_diplome_sessions', {
        diplome: data.diplome,
        antenne: data.antenne,
        etat: data.etat
      }).then(
        response => {
          context.commit('setAntenneDiplomeSessions', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des données",
          color: "red"
        })
      })

    },
    //Récupere la session selectionnée
    async fetchSessionById(context, url){
      return await ApiService.getRequest(url).then(
        response => {
          context.commit('setAntenneDiplomeSession', response.data);
        }
      ).catch( error => {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération de la session",
          color: "red"
        })
      })
    },
    //Ajoute une session
    async addSessionDiplome(context, formData){
      try{
        const response = await ApiService.postRequest('antenne_diplome_sessions', formData);
        context.commit('setAntenneDiplomeSession', response.data);

        Notify.create({
          message: "La session a bien été ajoutée",
          color: "green"
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout de la session",
          color: 'red'
        })
      }
    },
    //Modifie l'état d'une session
    async updateSessiondiplome(context, data){
      try{
        const response = ApiService.patchRequest(data.url, {etat: data.etat});
        context.commit('setAntenneDiplomeSessions', response.data);

        Notify.create({
          message: "La session a été modifiée avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification de la session",
          color: 'red'
        });
      }
    },
    //Modifie la session
    async editSessiondiplome(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.formData);
        context.commit('setAntenneDiplomeSessions', response.data);

        Notify.create({
          message: "La session a été modifiée avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification de la session",
          color: 'red'
        });
      }
    },

    //Ajoute délégué à la session
    async addDelegueSession(context, formData){
      try {
        const response = await ApiService.postRequest('antenne_diplome_session_delegues', formData);
        context.commit('setDelegues', response.data)
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Délégué ajouté avec succès",
          color: 'green'
        })
      }
    },
    // Modifie le délégue de la session
    async updateDelegueSession(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.newDelegate);
        context.commit('setDelegue', response.data);

        Notify.create({
          message:"Délégué modifié avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur est survenue lors de la modification du délégué",
          color: 'red'
        })
      }
    },
    // Supprime Un délégué
    async removeDelegue(context, url){
      try{
        await ApiService.deleteRequest(url);

        Notify.create({
          message: "Délégué supprimer avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur est survenue lors de la suppression du délégué",
          color: 'red'
        })
      }
    },
    // Modifie la liste des stagiaires d'une session
    async updateEtudiantsSession(context, data){
      try{
        await ApiService.patchRequest(data.url, data.stagiaires);

        Notify.create({
          message: "La session a été modifiée avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la mise à jour de la session",
          color: 'red'
        })
      }
    },
    // Transfert l'étudiant de session
    async updateListEtudiantSession(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.formData);
        context.commit('setEtudiantsDiplomeSession', response.data);

        Notify.create({
          message: "L'étudiant.e à été transféré.e avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors du transfère de l'étudiant.e",
          color: 'red'
        })
      }
    }

  },
  getters: {
    getAntenneDiplomeSessions(state) {
      return state.sessions;
    },
    getAntenneDiplomeSession(state) {
      return state.session;
    },
    getDelegue(state){
      return state.delegue;
    },
    getDelegues(state){
      return state.delegues;
    },
    getEtudiantsDiplomeSession(state){
      return state.etudiantsDiplomeSession;
    }
  }
}
