export const globalstore = {
  namespaced: false,
  state: {
      loading: false,
      backgrounds: [
        '1.jpg','2.jpg','3.jpg','4.jpg'
      ],
      colors:{
        entreprise: '#E8C6A4',
        stats: '#c5e2a5',
        antenne:"#92CE9E",
        candidature:'#90CCC6',
        stagiaire:'#D396BE',
        pedagogie:'#E09F9F',
        postformation:'#989DD6',
        taxes:'#6AA6DA'
      },
      formesJuridiques: [
        {id: 0, name: "OPC", long_name: "Organisme de placement collectif en valeurs mobilières sans personnalité morale"},
        {id: 10, name: "EI", long_name: "Entrepreneur individuel"},
        {id: 21, name: "In", long_name: "Indivision"},
        {id: 22, name: "SCF", long_name: "Société créée de fait"},
        {id: 23, name: "SePa", long_name: "Société en participation"},
        {id: 24, name: "Fi", long_name: "Fiducie"},
        {id: 27, name: "PHZC", long_name: "Paroisse hors zone concordataire"},
        {id: 29, name: "AGDP", long_name: "Autre groupement de droit privé non doté de la personnalité morale"},
        {id: 31, name: "PMDEI", long_name: "Personne morale de droit étranger, immatriculée au RCS (registre du commerce et des sociétés)"},
        {id: 32, name: "PMDENI", long_name: "Personne morale de droit étranger, non immatriculée au RCS"},
        {id: 41, name: "EP", long_name: "Etablissement public ou régie à caractère industriel ou commercial"},
        {id: 51, name: "SCCP", long_name: "Société coopérative commerciale particulière"},
        {id: 52, name: "SNC", long_name: "Société en nom collectif"},
        {id: 53, name: "SCo", long_name: "Société en commandite"},
        {id: 54, name: "SARL", long_name: "Société à responsabilité limitée (SARL)"},
        {id: 55, name: "SACA", long_name: "Société anonyme à conseil d administration"},
        {id: 56, name: "SAD", long_name: "Société anonyme à directoire"},
        {id: 57, name: "SAS", long_name: "Société par actions simplifiée"},
        {id: 58, name: "SE", long_name: "Société européenne"},
        {id: 61, name: "CEP", long_name: "Caisse d épargne et de prévoyance"},
        {id: 62, name: "GIE", long_name: "Groupement d intérêt économique"},
        {id: 63, name: "SCA", long_name: "Société coopérative agricole"},
        {id: 64, name: "SAM", long_name: "Société d assurance mutuelle"},
        {id: 65, name: "SCi", long_name: "Société civile"},
        {id: 69, name: "APMDPIRC", long_name: "Autre personne morale de droit privé inscrite au registre du commerce et des sociétés"},
        {id: 71, name: "AE", long_name: "Administration de l état"},
        {id: 72, name: "CT", long_name: "Collectivité territoriale"},
        {id: 73, name: "EPA", long_name: "Etablissement public administratif"},
        {id: 74, name: "APMFPA", long_name: "Autre personne morale de droit public administratif"},
        {id: 81, name: "OGRPSAO", long_name: "Organisme gérant un régime de protection sociale à adhésion obligatoire"},
        {id: 82, name: "OM", long_name: "Organisme mutualiste"},
        {id: 83, name: "CE", long_name: "Comité d entreprise"},
        {id: 84, name: "OP", long_name: "Organisme professionnel"},
        {id: 85, name: "ORANO", long_name: "Organisme de retraite à adhésion non obligatoire"},
        {id: 91, name: "SdPro", long_name: "Syndicat de propriétaires"},
        {id: 92, name: "ASSO", long_name: "Association loi 1901 ou assimilé"},
        {id: 93, name: "Fond", long_name: "Fondation"},
        {id: 99, name: "APMDP", long_name: "Autre personne morale de droit privé"},
      ],
      typesEntreprises: [
        {'id': 2, 'name': "Club sportif"},
        {'id': 1, 'name': "Entreprise"},
        {'id': 4, 'name': "Fondation"},
        {'id': 3, 'name': "Institutionnel"},
        {'id': 5, 'name': "Autre"}
      ],
      // garde les id's des types pour entreprise, association et autre
      statutsEntreprise: [
        { id: 1, name: 'Entreprise' },
        { id: 3, name: 'Association' },
        { id: 6, name: 'Société' },
        { id: 5, name: 'Autre' },
      ],
      activities: [
        { id: null, name: "Non renseignée" },
        {id: 1, name: "Culture et production animale, chasse et services annexes"},
        {id: 2, name: "Sylviculture et exploitation forestière"},
        {id: 3, name: "Pêche et aquaculture"},
        {id: 5, name: "Extraction de houille et de lignite"},
        {id: 6, name: "Extraction d'hydrocarbures"},
        {id: 7, name: "Extraction de minerais métalliques"},
        {id: 8, name: "Autres industries extractives"},
        {id: 9, name: "Services de soutien aux industries extractives"},
        {id: 10, name: "Industries alimentaires"},
        {id: 11, name: "Fabrication de boissons"},
        {id: 12, name: "Fabrication de produits à base de tabac"},
        {id: 13, name: "Fabrication de textiles"},
        {id: 14, name: "Industrie de l'habillement"},
        {id: 15, name: "Industrie du cuir et de la chaussure"},
        {id: 16, name: "Travail du bois et fabrication d'articles en bois et en liège, à l'exception des meubles ; fabrication d'articles en vannerie et sparterie"},
        {id: 17, name: "Industrie du papier et du carton"},
        {id: 18, name: "Imprimerie et reproduction d'enregistrements"},
        {id: 19, name: "Cokéfaction et raffinage"},
        {id: 20, name: "Industrie chimique"},
        {id: 21, name: "Industrie pharmaceutique"},
        {id: 22, name: "Fabrication de produits en caoutchouc et en plastique"},
        {id: 23, name: "Fabrication d'autres produits minéraux non métalliques"},
        {id: 24, name: "Métallurgie"},
        {id: 25, name: "Fabrication de produits métalliques, à l'exception des machines et des équipements"},
        {id: 26, name: "Fabrication de produits informatiques, électroniques et optiques"},
        {id: 27, name: "Fabrication d'équipements électriques"},
        {id: 28, name: "Fabrication de machines et équipements n.c.a."},
        {id: 29, name: "Industrie automobile"},
        {id: 30, name: "Fabrication d'autres matériels de transport"},
        {id: 31, name: "Fabrication de meubles"},
        {id: 32, name: "Autres industries manufacturières"},
        {id: 33, name: "Réparation et installation de machines et d'équipements"},
        {id: 35, name: "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné"},
        {id: 36, name: "Captage, traitement et distribution d'eau"},
        {id: 37, name: "Collecte et traitement des eaux usées"},
        {id: 38, name: "Collecte, traitement et élimination des déchets ; récupération"},
        {id: 39, name: "Dépollution et autres services de gestion des déchets"},
        {id: 41, name: "Construction de bâtiments"},
        {id: 42, name: "Génie civil"},
        {id: 43, name: "Travaux de construction spécialisés"},
        {id: 45, name: "Commerce et réparation d'automobiles et de motocycles"},
        {id: 46, name: "Commerce de gros, à l'exception des automobiles et des motocycles"},
        {id: 47, name: "Commerce de détail, à l'exception des automobiles et des motocycles"},
        {id: 49, name: "Transports terrestres et transport par conduites"},
        {id: 50, name: "Transports par eau"},
        {id: 51, name: "Transports aériens"},
        {id: 52, name: "Entreposage et services auxiliaires des transports"},
        {id: 53, name: "Activités de poste et de courrier"},
        {id: 55, name: "Hébergement"},
        {id: 56, name: "Restauration"},
        {id: 58, name: "Édition"},
        {id: 59, name: "Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale"},
        {id: 60, name: "Programmation et diffusion"},
        {id: 61, name: "Télécommunications"},
        {id: 62, name: "Programmation, conseil et autres activités informatiques"},
        {id: 63, name: "Services d'information"},
        {id: 64, name: "Activités des services financiers, hors assurance et caisses de retraite"},
        {id: 65, name: "Assurance"},
        {id: 66, name: "Activités auxiliaires de services financiers et d'assurance"},
        {id: 68, name: "Activités immobilières"},
        {id: 69, name: "Activités juridiques et comptables"},
        {id: 70, name: "Activités des sièges sociaux ; conseil de gestion"},
        {id: 71, name: "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques"},
        {id: 72, name: "Recherche-développement scientifique"},
        {id: 73, name: "Publicité et études de marché"},
        {id: 74, name: "Autres activités spécialisées, scientifiques et techniques"},
        {id: 75, name: "Activités vétérinaires"},
        {id: 77, name: "Activités de location et location-bail"},
        {id: 78, name: "Activités liées à l'emploi"},
        {id: 79, name: "Activités des agences de voyage, voyagistes, services de réservation et activités connexes"},
        {id: 80, name: "Enquêtes et sécurité"},
        {id: 81, name: "Services relatifs aux bâtiments et aménagement paysager"},
        {id: 82, name: "Activités administratives et autres activités de soutien aux entreprises"},
        {id: 84, name: "Administration publique et défense ; sécurité sociale obligatoire"},
        {id: 85, name: "Enseignement"},
        {id: 86, name: "Activités pour la santé humaine"},
        {id: 87, name: "Hébergement médico-social et social"},
        {id: 88, name: "Action sociale sans hébergement"},
        {id: 90, name: "Activités créatives, artistiques et de spectacle"},
        {id: 91, name: "Bibliothèques, archives, musées et autres activités culturelles"},
        {id: 92, name: "Organisation de jeux de hasard et d'argent"},
        {id: 93, name: "Activités sportives, récréatives et de loisirs"},
        {id: 94, name: "Activités des organisations associatives"},
        {id: 95, name: "Réparation d'ordinateurs et de biens personnels et domestiques"},
        {id: 96, name: "Autres services personnels"},
        {id: 97, name: "Activités des ménages en tant qu'employeurs de personnel domestique"},
        {id: 98, name: "Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre"},
        {id: 99, name: "Activités des organisations et organismes extraterritoriaux"},
      ],
      departements: [
        {'id': '01', 'name': '01 Ain'},
        {'id': '02', 'name': '02 Aisne'},
        {'id': '03', 'name': '03 Allier'},
        {'id': '04', 'name': '04 Alpes-de-Haute-Provence'},
        {'id': '05', 'name': '05 Hautes-Alpes'},
        {'id': '06', 'name': '06 Alpes-Maritimes'},
        {'id': '07', 'name': '07 Ardèche'},
        {'id': '08', 'name': '08 Ardennes'},
        {'id': '09', 'name': '09 Ariège'},
        {'id': '10', 'name': '10 Aube'},
        {'id': '11', 'name': '11 Aude'},
        {'id': '12', 'name': '12 Aveyron'},
        {'id': '13', 'name': '13 Bouches-du-Rhône'},
        {'id': '14', 'name': '14 Calvados'},
        {'id': '15', 'name': '15 Cantal'},
        {'id': '16', 'name': '16 Charente'},
        {'id': '17', 'name': '17 Charente-Maritime'},
        {'id': '18', 'name': '18 Cher'},
        {'id': '19', 'name': '19 Corrèze'},
        {'id': '2A', 'name': '2A Corse-du-Sud'},
        {'id': '2B', 'name': '2B Haute-Corse'},
        {'id': '21', 'name': '21 Côte-d\'Or'},
        {'id': '22', 'name': '22 Côtes d\'Armor'},
        {'id': '23', 'name': '23 Creuse'},
        {'id': '24', 'name': '24 Dordogne'},
        {'id': '25', 'name': '25 Doubs'},
        {'id': '26', 'name': '26 Drôme'},
        {'id': '27', 'name': '27 Eure'},
        {'id': '28', 'name': '28 Eure-et-Loir'},
        {'id': '29', 'name': '29 Finistère'},
        {'id': '30', 'name': '30 Gard'},
        {'id': '31', 'name': '31 Haute-Garonne'},
        {'id': '32', 'name': '32 Gers'},
        {'id': '33', 'name': '33 Gironde'},
        {'id': '34', 'name': '34 Hérault'},
        {'id': '35', 'name': '35 Ille-et-Vilaine'},
        {'id': '36', 'name': '36 Indre'},
        {'id': '37', 'name': '37 Indre-et-Loire'},
        {'id': '38', 'name': '38 Isère'},
        {'id': '39', 'name': '39 Jura'},
        {'id': '40', 'name': '40 Landes'},
        {'id': '41', 'name': '41 Loir-et-Cher'},
        {'id': '42', 'name': '42 Loire'},
        {'id': '43', 'name': '43 Haute-Loire'},
        {'id': '44', 'name': '44 Loire-Atlantique'},
        {'id': '45', 'name': '45 Loiret'},
        {'id': '46', 'name': '46 Lot'},
        {'id': '47', 'name': '47 Lot-et-Garonne'},
        {'id': '48', 'name': '48 Lozère'},
        {'id': '49', 'name': '49 Maine-et-Loire'},
        {'id': '50', 'name': '50 Manche'},
        {'id': '51', 'name': '51 Marne'},
        {'id': '52', 'name': '52 Haute-Marne'},
        {'id': '53', 'name': '53 Mayenne'},
        {'id': '54', 'name': '54 Meurthe-et-Moselle'},
        {'id': '55', 'name': '55 Meuse'},
        {'id': '56', 'name': '56 Morbihan'},
        {'id': '57', 'name': '57 Moselle'},
        {'id': '58', 'name': '58 Nièvre'},
        {'id': '59', 'name': '59 Nord'},
        {'id': '60', 'name': '60 Oise'},
        {'id': '61', 'name': '61 Orne'},
        {'id': '62', 'name': '62 Pas-de-Calais'},
        {'id': '63', 'name': '63 Puy-de-Dôme'},
        {'id': '64', 'name': '64 Pyrénées-Atlantiques'},
        {'id': '65', 'name': '65 Hautes-Pyrénées'},
        {'id': '66', 'name': '66 Pyrénées-Orientales'},
        {'id': '67', 'name': '67 Bas-Rhin'},
        {'id': '68', 'name': '68 Haut-Rhin'},
        {'id': '69', 'name': '69 Rhône'},
        {'id': '70', 'name': '70 Haute-Saône'},
        {'id': '71', 'name': '71 Saône-et-Loire'},
        {'id': '72', 'name': '72 Sarthe'},
        {'id': '73', 'name': '73 Savoie'},
        {'id': '74', 'name': '74 Haute-Savoie'},
        {'id': '75', 'name': '75 Paris'},
        {'id': '76', 'name': '76 Seine-Maritime'},
        {'id': '77', 'name': '77 Seine-et-Marne'},
        {'id': '78', 'name': '78 Yvelines'},
        {'id': '79', 'name': '79 Deux-Sèvres'},
        {'id': '80', 'name': '80 Somme'},
        {'id': '81', 'name': '81 Tarn'},
        {'id': '82', 'name': '82 Tarn-et-Garonne'},
        {'id': '83', 'name': '83 Var'},
        {'id': '84', 'name': '84 Vaucluse'},
        {'id': '85', 'name': '85 Vendée'},
        {'id': '86', 'name': '86 Vienne'},
        {'id': '87', 'name': '87 Haute-Vienne'},
        {'id': '88', 'name': '88 Vosges'},
        {'id': '89', 'name': '89 Yonne'},
        {'id': '90', 'name': '90 Territoire de Belfort'},
        {'id': '91', 'name': '91 Essonne'},
        {'id': '92', 'name': '92 Hauts-de-Seine'},
        {'id': '93', 'name': '93 Seine-St-Denis'},
        {'id': '94', 'name': '94 Val-de-Marne'},
        {'id': '95', 'name': '95 Val-D\'Oise'},
        {'id': '971', 'name': '971 Guadeloupe'},
        {'id': '972', 'name': '972 Martinique'},
        {'id': '973', 'name': '973 Guyane'},
        {'id': '974', 'name': '974 La Réunion'},
        {'id': '976', 'name': '976 Mayotte'}
      ],
      currentUser:{
        firstname: 'Emilie',
        lastname: 'Fauro',
        email: 'efauro@ilabs.fr',
        phone: '0607089010',
        antenne: 'CFA Futurosud',
        role: 'Administrateur',
      },
      roles:[
        "Administrateur", "Entreprise", "Stagiaire"
      ],
      tickets: [{
          title:'Ticket 1',
          module:'Candidature',
          content:'Contenu du ticket 1',
          type: '1'
        },{
          title:'Ticket 2',
          content:'Contenu du ticket 2',
          type: '2'
        }, {
          title:'Ticket 3',
          module:'Stagiaire',
          content:'Contenu du ticket 3',
          type: '1'
        }, {
          title:'Ticket 4',
          module:'Stagiaire',
          content:'Contenu du ticket 4',
          type: '1'
        }, {
          title:'Ticket 5',
          content:'Contenu du ticket 5',
          type: '2'
        }
      ],
      locales: {
          days: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
          daysShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
          months: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
          monthsShort: ['Janv','Fev','Mars','Avr','Mai','Juin','Juil','Aout','Sept','Oct','Nov','Dec'],
          firstDayOfWeek: 1
      },
      optionsNiveauDiplome: [{
        'id': 0,
        'name': 'Non renseigné'
      }, {
        'id': 1,
        'name': 'Niveau 3 (anciennement V)'
      }, {
        'id': 2,
        'name': 'Niveau 4 (anciennement IV)'
      }, {
        'id': 3,
        'name': 'Niveau 5 (anciennement III)'
      }, {
        'id': 4,
        'name': 'Niveau 6 (anciennement II)'
      }],
      nomenclatureNiveauDiplome: [
        { id: null, name: 'Non renseigné' },
        { id: 1, name: 'Niveau III (CAP - BEP)' },
        { id: 2, name: 'Niveau IV (BAC)' },
        { id: 3, name: 'Niveau V (BAC +2)' },
        { id: 4, name: 'Niveau VI (bac+3/4)' },
        { id: 5, name: 'Niveau VII (bac+5)' },
        { id: 6, name: 'Niveau VIII (Bac +8)' },
        { id: 7, name: 'Sans Niveau' },
      ],
    //tag: ne pas toucher aux tags déjà définis - utilisés pour identifier un document - string
    //pedagogic: type de la pièce (si la pièce réglementaire est de type pédagogic ou pas - booléen
    // Si 'pedagogic' valeur change, il faut changer les tags dans params.cfa
      typeDocuments:[
        {id: null, name: 'Non renseigné',                         expires: false, pedagogic: true, tag: 'non_renseigne'},

        {id: 79, name: 'Attestation CPAM',                        expires: false, pedagogic: false, tag: 'attestation_cpam'},
        {id: 72, name: 'Attestation d\'entrée en formation',      expires: false, pedagogic: false, tag: 'attestation_entree_formation'},
        {id: 85, name: 'Attestation d\'inscription en formation', expires: false, pedagogic: false, tag: 'attestation_inscription_formation'},
        {id: 69, name: 'Attestation Campus',                      expires: false, pedagogic: false, tag: 'attestation_campus'},
        {id: 67, name: 'Attestation de complétude du dossier',    expires: false, pedagogic: false, tag: 'attestation_completude_dossier'},
        {id: 68, name: 'Attestation de complétude du dossier spécifique FFE', expires: false, pedagogic: false, tag: 'attestation_completude_dossier_specifique_ffe'},
        {id: 31, name: 'Attestation de fin de formation',         expires: false, pedagogic: false, tag: 'attestation_fin_formation'},
        {id: 86, name: 'Attestation de prise de connaissance',    expires: false, pedagogic: false, tag: 'attestation_prise_connaissance'},
        {id: 1,  name: 'Attestation de sécurité sociale',         expires: false, pedagogic: false, tag: 'attestation_securite_sociale'},
        {id: 2,  name: 'Attestation de responsabilité civile',    expires: false, pedagogic: false, tag: 'attestation_responsabilite_civile'},
        {id: 80, name: 'Attestation RQTH',                        expires: false, pedagogic: false, tag: 'attestation_rqth'},
        {id: 27, name: 'Attestations TEP',                        expires: false, pedagogic: false, tag: 'attestations_tep'},
        {id:99,  name: 'Attestation 400m',                        expires: false, pedagogic: false, tag: 'attestation_400m'},
        {id:100, name: 'Attestation Rugby',                       expires: false, pedagogic: false, tag: 'attestation_rugby'},
        {id: 77, name: 'Autorisation de travail',                 expires: false, pedagogic: false, tag: 'autorisation_travail'},
        {id: 78, name: 'Avis de situation FT',                    expires: false, pedagogic: false, tag: 'avis_situation_ft'},
        {id: 26, name: 'BNSSA',                                   expires: false, pedagogic: false, tag: 'bnssa'},
        {id: 92, name: 'Bulletins de salaire',                    expires: false, pedagogic: false, tag: 'bulletins_salaire'},
        {id: 103, name: 'Bulletins',                              expires: false, pedagogic: false, tag: 'bulletins'},
        {id: 13, name: 'Carte de séjour',                         expires: true, pedagogic: false, tag: 'carte_sejour'},
        {id: 28, name: 'Carte professionnelle',                   expires: false, pedagogic: false, tag: 'carte_professionnelle'},
        {id:101, name: 'Carte vitale',                            expires: false, pedagogic: false, tag: 'carte_vitale'},
        {id: 70, name: 'Cerfa ou contrat de formation',           expires: false, pedagogic: false, tag: 'cerfa_contrat_formation'},
        {id: 3,  name: 'Certificat journée de défense citoyenne', expires: false, pedagogic: false, tag: 'certificat_jdc'},
        {id: 10, name: 'Certificat médical',                      expires: false, pedagogic: false, tag: 'certificat_medical'},
        {id: 29, name: 'Chèque',                                  expires: false, pedagogic: false, tag: 'cheque'},
        {id: 12, name: 'Consentement RGPD',                       expires: false, pedagogic: false, tag: 'consentement_rgpd'},
        {id: 88, name: 'Contrat de formation',                    expires: false, pedagogic: false, tag: 'contrat_formation'},
        {id: 71, name: 'Convention de formation',                 expires: false, pedagogic: false, tag: 'convention_formation'},
        {id: 90, name: 'Convention de prêt de chromebook',        expires: false, pedagogic: false, tag: 'convention_pret_chromebook'},
        {id: 89, name: 'Convention de stage',                     expires: false, pedagogic: false, tag: 'convention_stage'},
        {id: 76, name: 'Courrier admission',                      expires: false, pedagogic: false, tag: 'courrier_admission'},
        {id: 5,  name: 'CNI',                                     expires: true, pedagogic: false, tag: 'cni'},
        {id: 6,  name: 'CNI Europe',                              expires: true, pedagogic: false, tag: 'cni_europe'},
        {id: 65, name: 'CV',                                      expires: false, pedagogic: false, tag: 'cv'},
        {id: 24, name: 'Diplômes',                                expires: false, pedagogic: false, tag: 'documents_diplomes'},
        {id: 25, name: 'Dossier d\'inscription',                  expires: false, pedagogic: false, tag: 'dossier_inscription'},
        {id: 87, name: 'Droit à l\'image',                        expires: false, pedagogic: false, tag: 'droit_image'},
        {id: 73, name: 'Droit à l\'image CFA',                    expires: false, pedagogic: false, tag: 'droit_image_cfa'},
        {id: 74, name: 'Droit à l\'image hors CFA',               expires: false, pedagogic: false, tag: 'droit_image_hors_cfa'},
        {id: 4,  name: 'Extrait casier judiciaire',               expires: false, pedagogic: false, tag: 'extrait_casier_judiciaire'},
        {id: 9,  name: 'Fiche de liaison',                        expires: false, pedagogic: false, tag: 'fiche_liaison'},
        {id: 84, name: 'Fiche de prescription',                   expires: false, pedagogic: false, tag: 'fiche_prescription'},
        {id: 64, name: 'Fiche signalétique',                      expires: false, pedagogic: false, tag: 'fiche_signalitique'},
        {id: 66, name: 'Licence FFE',                             expires: false, pedagogic: false, tag: 'licence_ffe'},
        {id: 91, name: 'Notification rejet ARE France Travail (- 3 mois)', expires: false, pedagogic: false, tag: 'notification_rejet_are_france_travail_moins_3_mois'},
        {id: 7,  name: 'Passeport',                               expires: true, pedagogic: false, tag: 'passeport'},
        {id:104, name: 'Paiement CVEC',                           expires: false, pedagogic: false, tag: 'paiement_cvec'},
        {id: 22, name: 'PSC1',                                    expires: false, pedagogic: false, tag: 'psc1'},
        {id: 23, name: 'PSE1',                                    expires: false, pedagogic: false, tag: 'pse1'},
        {id: 8,  name: 'RIB',                                     expires: false, pedagogic: false, tag: 'rib'},
        {id: 75, name: 'Règlement intérieur',                     expires: false, pedagogic: false, tag: 'reglement_interieur'},
        {id: 102, name: 'Relevé de notes',                        expires: false, pedagogic: false, tag: 'releve_notes'},
        {id: 92, name: 'RS1 (CERFA pour rému)',                   expires: false, pedagogic: false, tag: 'rs1_cerfa_remu'},
        {id: 11, name: 'Validité Pôle Emploi',                    expires: true, pedagogic: false, tag: 'validite_pole_emploi'},
        {id: 105, name: 'Autres Documents',                       expires: false, pedagogic: false, tag: 'autres_documents'},

        // --- Dernier id : 105
        {id: 30, name: 'Attestation d\'assiduité formation',          expires: false, pedagogic: true, tag: 'attestation_assiduite_formation'},
        {id: 17, name: 'Attestation EPMSP',                           expires: false, pedagogic: true, tag: 'attestation_epmsp'},
        {id: 58, name: 'Attestation réussite',                        expires: false, pedagogic: true, tag: 'attestation_reussite'},
        {id: 59, name: 'Attestation réussite partielle',              expires: false, pedagogic: true, tag: 'attestation_reussite_partielle'},
        {id: 60, name: 'Attestation sortie',                          expires: false, pedagogic: true, tag: 'attestation_sortie'},
        {id: 83, name: 'Bilan de fin de parcours',                    expires: false, pedagogic: true, tag: 'bilan_fin_parcours'},
        {id: 19, name: 'BTI / Entretiens',                            expires: false, pedagogic: true, tag: 'bti_entretiens'},
        {id: 32, name: 'Bulletin 1er semestre (1ère année)',          expires: false, pedagogic: true, tag: 'bulletin_1er_semestre_1_annee'},
        {id: 33, name: 'Bulletin 2ème semestre (1ère année)',         expires: false, pedagogic: true, tag: 'bulletin_2eme_semestre_1_annee'},
        {id: 34, name: 'Bulletin 1er semestre (2ère année)',          expires: false, pedagogic: true, tag: 'bulletin_1er_semestre_2_annee'},
        {id: 35, name: 'Bulletin 2ème semestre (2ème année)',         expires: false, pedagogic: true, tag: 'bulletin_2eme_semestre_2_annee'},
        {id: 36, name: 'Certificat de realisation',                   expires: false, pedagogic: true, tag: 'certificat_realisation'},
        {id: 37, name: 'Certificat de scolarité (1ère année)',        expires: false, pedagogic: true, tag: 'certificat_scolarite_1_annee'},
        {id: 50, name: 'Certificat de scolarité (2ème année)',        expires: false, pedagogic: true, tag: 'certificat_scolarite_2_annee'},
        {id: 38, name: 'Certification de réalisation 1/4',            expires: false, pedagogic: true, tag: 'certificat_realisation_1/4'},
        {id: 39, name: 'Certification de réalisation 2/4',            expires: false, pedagogic: true, tag: 'certificat_realisation_2/4'},
        {id: 40, name: 'Certification de réalisation 3/4',            expires: false, pedagogic: true, tag: 'certificat_realisation_3/4'},
        {id: 41, name: 'Certification de réalisation 4/4',            expires: false, pedagogic: true, tag: 'certificat_realisation_4/4'},
        {id: 81, name: 'Contrat pédagogique',                         expires: false, pedagogic: true, tag: 'contrat_pedagogique'},
        {id: 97, name: 'Dossier CPAP',                                expires: false, pedagogic: true, tag: 'dossier_cpap'},
        {id: 42, name: 'Dossier de candidature',                      expires: false, pedagogic: true, tag: 'dossier_candidature'},
        {id: 56, name: 'Emargement 1er équipement',                   expires: false, pedagogic: true, tag: 'emargement_1er_equipement'},
        {id: 55, name: 'Equivalence',                                 expires: false, pedagogic: true, tag: 'equivalence'},
        {id: 18, name: 'Evaluations formatives',                      expires: false, pedagogic: true, tag: 'evaluations_formatives'},
        {id: 15, name: 'Fiche de positionnement',                     expires: false, pedagogic: true, tag: 'positionnement'},
        {id: 57, name: 'Fiche de poste',                              expires: false, pedagogic: true, tag: 'fiche_poste'},
        {id: 43, name: 'Fiche evaluation tuteur',                     expires: false, pedagogic: true, tag: 'fiche_evaluation_tuteur'},
        {id: 46, name: 'Fiche evaluation tuteur 1/4',                 expires: false, pedagogic: true, tag: 'fiche_evaluation_tuteur_1/4'},
        {id: 47, name: 'Fiche évaluation tuteur 2/4',                 expires: false, pedagogic: true, tag: 'fiche_evaluation_tuteur_2/4'},
        {id: 48, name: 'Fiche évaluation tuteur 3/4',                 expires: false, pedagogic: true, tag: 'fiche_evaluation_tuteur_3/4'},
        {id: 49, name: 'Fiche évaluation tuteur 4/4',                 expires: false, pedagogic: true, tag: 'fiche_evaluation_tuteur_4/4'},
        {id: 16, name: 'Fiche mission',                               expires: false, pedagogic: true, tag: 'fiche_mission'},
        {id: 21, name: 'Grille de certification signée',              expires: false, pedagogic: true, tag: 'grille_certification'},
        // {id: 63, name: 'Grille de positionnement',                    expires: false, pedagogic: true, tag: 'grille_positionnement'},
        {id: 61, name: 'Grille de sélection',                         expires: false, pedagogic: true, tag: 'grille_selection'},
        // {id: 62, name: 'Grille TEP',                                  expires: false, pedagogic: true, tag: 'grille_tep'},
        {id: 82, name: 'Livret de suivi individuel de parcours',      expires: false, pedagogic: true, tag: 'livret_suivi_individuel_parcours'},
        {id: 54, name: 'PIF',                                         expires: false, pedagogic: true, tag: 'pif'},
        {id: 44, name: 'Premier BTS Blanc (1ère année)',              expires: false, pedagogic: true, tag: 'premier_bts_blanc'},
        {id: 50, name: 'Questionnaire pédagogique 1 (1ère année)',    expires: false, pedagogic: true, tag: 'questionnaire_peda_1_1_annee'},
        {id: 51, name: 'Questionnaire pédagogique 2 (1ère année)',    expires: false, pedagogic: true, tag: 'questionnaire_peda_2_1_annee'},
        {id: 52, name: 'Questionnaire pédagogique 1 (2ème année)',    expires: false, pedagogic: true, tag: 'questionnaire_peda_1_2_annee'},
        {id: 53, name: 'Questionnaire pédagogique 2 (2ème année)',    expires: false, pedagogic: true, tag: 'questionnaire_peda_2_2_annee'},
        {id: 98, name: 'Relevé de notes BTS',                         expires: false, pedagogic: true, tag: 'releve_de_notes_bts'},
        {id: 45, name: 'Second BTS Blanc (2ème année)',               expires: false, pedagogic: true, tag: 'second_bts_blanc'},
        {id: 95, name: 'Suivi entreprise 1',                          expires: false, pedagogic: true, tag: 'suivi_entreprise_1'},
        {id: 96, name: 'Suivi entreprise 2',                          expires: false, pedagogic: true, tag: 'suivi_entreprise_2'},
        {id: 14, name: 'Test des Exigences Préalable (TEP)',          expires: false, pedagogic: true, tag: 'test_exigences'},
        {id: 20, name: 'Visites en structure',                        expires: false, pedagogic: true, tag: 'visites_structure'},


      ],
      typeExports: [
        { label: 'Export stagiaires', value: 'stagiaires', module: 'stagiaire' },
        { label: 'Export de validation des documents pédagogiques', value: 'documents_pedagogic', module: 'stagiaire' },
        { label: 'Export des absences', value: 'absences', module: 'stagiaire' },
        { label: 'Export SIFA', value: 'exportSifa', module: 'stagiaire' }
      ],
      civilites:[
        {id: 0, name: 'Monsieur', short: 'M.'},
        {id: 1, name: 'Madame', short: 'Mme.'}
      ],
      situationMatrimoniale: [
        {id: 0, name: "Non renseigné"},
        {id: 1, name: "Célibataire"},
        {id: 8, name: "Divorcé(e)"},
        {id: 4, name: "En concubinage"},
        {id: 2, name: "Marié(e)"},
        {id: 3, name: "Pacsé(e)"},
        {id: 5, name: "Union libre"},
        {id: 7, name: "Séparé(e)"},
        {id: 6, name: "Veuf/Veuve"},
      ],
      typeCursus: [
        {id: null, name: 'Non renseigné', apprenti: false},
        {id: 1, name: 'Apprentissage', apprenti: true},
        {id: 10, name: 'Apprentissage sans employeur', apprenti: true},
        {id: 9, name: 'Hors apprentissage', apprenti: false},
      ],
      niveauDeFormation:[
        'BREVET',
        'CAP, BEP',
        'Bac Baccalauréat',
        'Bac + 2',
        'Bac + 3',
        'Bac + 4',
        'Bac + 5',
        'Bac + 8',
        'Pas de diplôme',
        'Non renseigné',
      ],
      situationEtudiant: [
        'Contrat aidé',
        'Contrat d\'apprentissage',
        'Contrat de professionnalisation',
        'Demandeur d\'emploi',
        'Etudiant',
        'Inactif',
        'Prépa apprentissage',
        'Salarié',
        'Scolaire',
        'Service national/civique',
        'Stagiaire',
        'Stagiaire sans contrat apprentissage',
        'Stagiaire suite rupture',
        'Autre',
        'Non renseigné',

        // '',
        // 'CDD temps partiel (- 6 mois)',
        // 'CDD temps partiel (+ 6 mois)',
        // 'CDD temps plein (- 6 mois)',
        // 'CDD temps plein (+ 6 mois)',
        // 'CDI temps partiel',
        // 'CDI temps plein',
        // 'Contrat saisonnier',
        // 'Contrat aidé',
        // 'Missions d\'intérim',
        // 'Indépendant ou création d\'entreprise (Micro-entreprise)',
        // 'Indépendant ou création d\'entreprise (Société ou entreprise)',
        // 'En contrat d\'apprentissage (Même niveau de diplôme)',
        // 'En contrat d\'apprentissage (Niveau de diplôme supérieur)',
        // 'En contrat de professionnalisation (Même niveau de diplôme)',
        // 'En contrat de professionnalisation (Niveau de diplôme supérieur)',
      ],
      financement: [
        'AGEFIPH',
        'CPF',
        'CPF DE TRANSITION',
        'Entreprise',
        'Financement personnel',
        'Mission Locale',
        'OPCO',
        'Pôle emploi',
        'Région',
        'SESAME',
        'Autre',
        'Co-finencement'
      ],
      opcoDetails: [
        "AFDAS",
        "AKTO",
        "ATLAS",
        "CONSTRUCTYS",
        "OCAPIAT",
        "OPCO 2I",
        "OPCO OPCOMMERCE",
        "OPCO EP",
        "OPCO MOBILITÉS",
        "OPCO SANTÉ",
        "UNIFORMATION",
        "AUTRE"
      ],
      listeOpcos: [
        { id: null, name: "Non renseignée" },
        { id: 1, name: "AFDAS" },
        { id: 2, name: "AKTO" },
        { id: 3, name: "ATLAS" },
        { id: 4, name: "CONSTRUCTYS" },
        { id: 5, name: "OCAPIAT" },
        { id: 6, name: "OPCO 2I" },
        { id: 7, name: "OPCO OPCOMMERCE" },
        { id: 8, name: "OPCO EP" },
        { id: 9, name: "OPCO MOBILITÉS" },
        { id: 10, name: "OPCO SANTÉ" },
        { id: 11, name: "UNIFORMATION" }
      ],
      listeFinancementAutre: [
        { id: 0, name: "Opco" },
        { id: 1, name: "Autre" },
        { id: 2, name: "Région" },
        { id: 3, name: "FSE" },
        { id: 4, name: "État" },
        { id: 5, name: "Département" },
        { id: 6, name: "Ville" },
        { id: 7, name: "Agefiph" },
      ],
      statutEtudiant: [
        {id: 1, name: 'Apprenti', has_enterprise: 1, has_diplome: true, color: 'blue', precision: false}, //status abandonné pour le 8
        {id: 7, name: 'Contrat aidé', has_enterprise: 1, has_diplome: false, color: 'pink', precision: false},
        {id: 6, name: 'Contrat de professionnalisation', has_enterprise: 1, has_diplome: true, color: 'purple', precision: false},
        {id: 3, name: 'Demandeur d\'emploi', has_enterprise: 0, has_diplome: false, color: 'blue-grey', precision: false},
        {id: 4, name: 'Salarié', has_enterprise: 1, has_diplome: false, color: 'orange', precision: true},
        {id: 2, name: 'Stagiaire FP', has_enterprise: 1, has_diplome: false, color: 'indigo', precision: false},
        {id: 8, name: 'Stagiaire FP App.', has_enterprise: 1, has_diplome: false, color: 'cyan', precision: false}, //nouveau status apprenti
        {id: 5, name: 'Autre', has_enterprise: 0, has_diplome: false, color: 'grey', precision: true},
        {id: null, name: 'Non renseigné', has_enterprise: 0, has_diplome: false, color: 'grey', precision: false},
      ],
      optionSalarie: [
        'Salarié de droit commun',
        'PRO A',
        'Contrat de professionnalisation',
        'PEC' ,
        'PTP'
      ],
      optionsYesNo:[
        { id: null, name: 'Non renseigné' },
        { id: 1, name: 'Oui' },
        { id: 0, name: 'Non' }
      ],
      optionsTrueFalseToYesNo:[
        { id: true, name: 'Oui' },
        { id: false, name: 'Non' }
      ],
      optionsYesNoBool:[
        { id: null, name: 'Non renseigné' },
        { id: true, name: 'Oui' },
        { id: false, name: 'Non' }
      ],
      optionsTypeSession:[
        { id: null, name: 'Non renseigné' },
        { id: 1, name: 'Complète' },
        { id: 0, name: 'Allégé' }
      ],
      optionsRefusMotif: [
        'Abandon avant entrée',
        'Hors critères d\'admissibilité',
        'Situation administrative incomplète',
      ],
      // optionsGroupColor:[
      //   {id: 'red', name:'Rouge'},
      //   {id: 'orange', name:'Orange'},
      //   {id: 'deep-orange', name:'Orange foncé'},
      //   {id: 'amber', name:'Ambre'},
      //   {id: 'yellow', name:'Jaune'},
      //   {id: 'lime', name:'Vert citron'},
      //   {id: 'green', name:'Vert'},
      //   {id: 'light-green', name:'Vert clair'},
      //   {id: 'cyan', name:'Cyan'},
      //   {id: 'teal', name:'Sarcelle'},
      //   {id: 'blue', name:'Bleu'},
      //   {id: 'light-blue', name:'Bleu clair'},
      //   {id: 'purple', name:'Violet'},
      //   {id: 'deep-purple', name:'Violet foncé'},
      //   {id: 'indigo', name:'Mauve'},
      //   {id: 'pink', name:'Rose'},
      //   {id: 'brown', name:'Marron'},
      //   {id: 'grey', name:'Gris'},
      //   {id: 'blue-grey', name:'Bleu gris'},
      //   {id: 'black', name:'Noir'}
      // ],
      optionsGroupColor:[
        {id: 'red-3', name:'Rouge'},
        {id: 'pink-3', name:'Rose'},
        {id: 'purple-3', name:'Violet'},
        {id: 'deep-purple-3', name:'Violet foncé'},
        {id: 'indigo-3', name:'Indigo'},
        {id: 'blue-3', name:'Bleu'},
        {id: 'light-blue-3', name:'Bleu clair'},
        {id: 'cyan-3', name:'Cyan'},
        {id: 'teal-3', name:'Sarcelle'},
        {id: 'green-3', name:'Vert'},
        {id: 'light-green-3', name:'Vert clair'},
        {id: 'lime-3', name:'Vert citron'},
        {id: 'yellow-3', name:'Jaune'},
        {id: 'amber-3', name:'Ambre'},
        {id: 'orange-3', name:'Orange'},
        {id: 'deep-orange-3', name:'Orange foncé'},
        {id: 'brown-3', name:'Marron'},
        {id: 'grey-3', name:'Gris'},
        {id: 'blue-grey-3', name:'Bleu gris'},

      ],
      utilisateurRole: [
        { 'id': 'ROLE_SUPERVISOR', 'name': 'Superviseur',  },
        { 'id': 'ROLE_ADMIN', 'name': 'Administrateur' },
        { 'id': 'ROLE_COORDINATOR', 'name': 'Coordinateur' },
        { 'id': 'ROLE_USER', 'name': 'Utilisateur' },
        { 'id': 'ROLE_GUEST', 'name': 'Invité' },
      ],
      // ! Si tu change ici, faut changer dans l'entité EtudiantInterview
      interviewsType:[
        // Dernier ID = 21
        {id: 2, name: "Entretien d'entrée",         tag:'entretiens_entree'},
        {id: 3, name: "Entretien mi parcours",      tag:'entretien_mi_parcours'},
        {id: 1, name: "Entretien fin de parcours",  tag:'entretien_fin_parcours'},
        {id: 21, name: "Réferent école",            tag:'referent_ecole'},
        {id: 4, name: "Suivi apprentissage",        tag:'suivi_apprentissage'},
        {id: 6, name: "Suivi formation",            tag:'suivi_formation'},
        {id: 7, name: "Suivi structure",            tag:'suivi_structure'},
        {id: 5, name: "Autre",                      tag:'autre'},

        // Pour AFEPT
        {id: 8, name: "Accompagnement santé social",                  tag:'accompagnement_sante_social'},
        {id: 16, name: "Bilan intermédiaire",                         tag:'bilan_intermediaire'},
        {id: 14, name: "Contractualisation et engagement",            tag:'contractualisation_engagement'},
        {id: 10, name: "Entretien d’accueil",                         tag:'entretien_accueil'},
        {id: 18, name: "Entretien d’écoute",                          tag:'entretien_ecoute'},
        {id: 17, name: "Entretien de bilan de fin de parcours",       tag:'entretien_bilan_fin_parcours'},
        {id: 11, name: "Entretien référent handicap",                 tag:'entretien_referent_handicap'},
        {id: 19, name: "Entretien de régulation",                     tag:'entretien_regulation'},
        {id: 13, name: "Entretien de restitution de positionnement",  tag:'entretien_restitution_positionnement'},
        {id: 15, name: "Entretien de suivi pédagogique",              tag:'entretien_suivi_pedagogique'},
        {id: 9, name: "Entretien de suivi projet professionnel",      tag:'entretien_suivi_projet_professionnel'},
        {id: 12, name: "Positionnement",                              tag:'positionnement'},
        {id: 20, name: "Signalement",                                 tag:'signalement'},
      ],
      interviewsTypeDetails:[
        // Dernier ID = 6
        {id: 1, type: 6, name: "Suivi numéro 1"},
        {id: 2, type: 6, name: "Suivi numéro 2"},
        {id: 3, type: 6, name: "Suivi numéro 3"},
        {id: 4, type: 7, name: "Suivi numéro 1"},
        {id: 5, type: 7, name: "Suivi numéro 2"},
        {id: 6, type: 7, name: "Suivi numéro 3"},
      ],
      interviewMode:[
        {'id': null, 'name': "Non renseigné"},
        {'id': '2', 'name': "Courrier"},
        {'id': '4', 'name': "Email"},
        {'id': '3', 'name': "Entretien"},
        {'id': '5', 'name': "Messagerie"},
        {'id': '1', 'name': "Téléphonique"},
        {'id': '6', 'name': "Autre"},
      ],
      // * Si tu change les raisons d'absence ici
      // * Il faut que tu les change aussi dans l'entitée EtudiantAbsence
      raisonAbsence: [
        // Dernier ID = 19
        {'id':1, 'name':'Absence injustifiée centre'},
        {'id':2, 'name':'Absence injustifiée entreprise'},
        {'id':3, 'name':'Absence justifiée centre'},
        {'id':4, 'name':'Absence centre pour sanction'},
        {'id':5, 'name':'Absence entreprise pour sanction'},
        {'id':6, 'name':'Accident de travail centre'},
        {'id':7, 'name':'Accident de travail entreprise'},
        {'id':8, 'name':'Activité pédagogique extérieure'},
        {'id':9, 'name':'Arrêt maladie centre'},
        {'id':10, 'name':'Congés payés'},
        {'id':19, 'name':'Exclusion'},
        {'id':19, 'name':'Fête religieuse'},
        {'id':11, 'name':'Libéré'},
        {'id':12, 'name':'Maternité'},
        {'id':13, 'name':'Mise à pied'},
        {'id':14, 'name':'Recherche de stage'},
        {'id':15, 'name':'Rendez-vous'},
        {'id':16, 'name':'Repos hebdomadaire'},
        {'id':17, 'name':'Retard'},
        {'id':18, 'name':'Suspension de parcours'},
      ],
      suivisSteps: [
        {'id': 1, 'name':"3 Mois"},
        {'id': 2, 'name':"6 Mois"},
        {'id': 3, 'name':"9 Mois"},
        {'id': 4, 'name':"12 Mois"}
      ],
      situations: [
        {id: 1, name:'En Activité professionnelle'},
        {id: 2, name:"En recherche d'emploi"},
        {id: 3, name:"En formation"},
        {id: 4, name:"Autre"},
      ],
      typesContrats: [
        {id: null, name: "Non renseigné"},
        {id: 1, name: "CDD temps partiel (- 6 mois)"},
        {id: 2, name: "CDD temps partiel (+ 6 mois)"},
        {id: 3, name: "CDD temps plein (- 6 mois)"},
        {id: 4, name: "CDD temps plein (+ 6 mois)"},
        {id: 5, name: "CDI temps partiel"},
        {id: 6, name: "CDI temps plein"},
        {id: 7, name: "Contrat saisonnier"},
        {id: 8, name: "Contrat aidé"},
        {id: 9, name: "Missions d\'intérim"},
        {id: 10, name: "Indépendant ou création d\'entreprise (Micro-entreprise)"},
        {id: 11, name: "Indépendant ou création d\'entreprise (Société ou entreprise)"},
        {id: 12, name: "En contrat d\'apprentissage (Même niveau de diplôme)"},
        {id: 13, name: "En contrat d\'apprentissage (Niveau de diplôme supérieur)"},
        {id: 14, name: "En contrat de professionnalisation (Même niveau de diplôme)"},
        {id: 15, name: "En contrat de professionnalisation (Niveau de diplôme supérieur)"},
      ],
      // contrats: [
      //   {id: 0, name: "Sans contrat"},
      //   {id: 1, name: "CDI"},
      //   {id: 2, name: "CDD"},
      //   {id: 3, name: "Alternance"},
      //   {id: 4, name: "Professionnalisation"},
      //   {id: 5, name: "Interim"},
      //   {id: 6, name: "Autre"}
      // ],
      diplomeDurations: [
        {'id': 1,'name': "Jours"},
        {'id': 2,'name': "Mois"},
        {'id': 3,'name': "Années"}
      ],
      entreprisePartenaire: [
        {'id': 0 ,'name': "Non"},
        {'id': 1 ,'name': "Oui"},
      ],
      groupeYear: [
        {'id': '1' ,'name': "2021"},
        {'id': '2' ,'name': "2022"},
        {'id': '3' ,'name': "2023"},
        {'id': '4' ,'name': "2024"},
        {'id': '5' ,'name': "2025"},
      ],
      yearClosing: [
        {'id': '1' ,'name': "2021"},
        {'id': '2' ,'name': "2022"},
        {'id': '3' ,'name': "2023"},
        {'id': '4' ,'name': "2024"},
        {'id': '5' ,'name': "2025"},
      ],
      ticketModule: [
        {'id': '1' ,'name': "Antenne"},
        {'id': '2' ,'name': "Candidature"},
        {'id': '3' ,'name': "Stagiaire"},
        {'id': '4' ,'name': "Pédagogie"},
        {'id': '5' ,'name': "Entreprise"},
        {'id': '6' ,'name': "Post Formation"},
      ],
      ticketType: [
        {'id': '1' ,'name': "Maintenance"},
        {'id': '2' ,'name': "Aide"},
      ],
      ticketStatus: [
        {'id': '1' ,'name': "En cours"},
        {'id': '2' ,'name': "En attente"},
        {'id': '3' ,'name': "Résolu"},
      ],
      entrepriseClosed: [
        {'id': '1' ,'name': "Entreprises archivées"},
      ],
      entrepriseTaille: [
        {id: null, name: 'Non renseigné'},
        {id: 1 , name: "- de 10 salariés"},
        {id: 2 , name: "de 10 à 50 salariés"},
        {id: 3 , name: "de 50 à 100 salariés"},
        {id: 4 , name: "+ de 100 salariés"},
      ],
      stagiaireEtat: [
        {'id': 0 ,'name': "Présent"},
        {'id': 1 ,'name': "Sortie"},
        {'id': 2 ,'name': "Archivé"},
        {'id': 3 ,'name': "Supprimé"},
      ],
    stagiaireEtatParours: [
        {id: null, name: 'Non renseigné'},
        {id: 2, name: 'En contrat'},
        {id: 1, name: 'En formation'},
        {id: 3, name: 'En rupture'},
    ],
      sortieMotifStagiaire: [
        {id: null, name: null},
        {id: '10', name: 'Abandon de la formation'},
        {id: '2', name: 'En recherche d’employeur délai 3 mois écoulé sans avoir trouvé d’employeur'},
        {id: '11', name: 'Exclusion du CFA'},
        {id: '1', name: 'Fin de contrat et de formation'},
        {id: '4', name: 'Rupture du contrat durant la période d’essai sans maintien en formation à l’initiative de l’alternant'},
        {id: '5', name: 'Rupture du contrat durant la période d’essai sans maintien en formation à l’initiative de l’employeur'},
        {id: '6', name: 'Rupture du contrat durant la période d’essai sans maintien en formation d’un commun accord'},
        {id: '7', name: 'Rupture du contrat après la période d’essai sans maintien en formation à l’initiative de l’alternant'},
        {id: '8', name: 'Rupture du contrat après la période d’essai sans maintien en formation à l’initiative de l’employeur'},
        {id: '9', name: 'Rupture du contrat après la période d’essai sans maintien en formation d’un commun accord'},
        {id: '12', name: 'Rupture du contrat durant la période d’essai avec maintien en formation à l’initiative de l’alternant'},
        {id: '13', name: 'Rupture du contrat durant la période d’essai avec maintien en formation à l’initiative de l’employeur'},
        {id: '14', name: 'Rupture du contrat durant la période d’essai avec maintien en formation d’un commun accord'},
        {id: '15', name: 'Rupture du contrat après la période d’essai avec maintien en formation à l’initiative de l’alternant'},
        {id: '16', name: 'Rupture du contrat après la période d’essai avec maintien en formation à l’initiative de l’employeur'},
        {id: '17', name: 'Rupture du contrat après la période d’essai avec maintien en formation d’un commun accord'},
        {id: '3', name: 'Suite rupture de contrat délai de 6 mois écoulé sans avoir trouvé d’employeur'},
      ],
      typeCandidature: [
        {'id': 1, 'name': 'Potentielle'},
        {'id': 2, 'name': 'Prescripte'},
      ],
      opcos: [
        {siren: 851240499, name: 'OPCO Mobilités'},
        {siren: 851296632, name: 'ATLAS'},
        {
          siren: 398522243,
          name: "L'Opcommerce",
          cfaDock: {
            tokenUrl: 'https://login.microsoftonline.com/751df523-5a91-44b0-b817-4a7d82b71dc0/oauth2/v2.0/token',
            scope: 'https://lopcommerceb2c.onmicrosoft.com/api-inter-opco/.default',
            clientId: 'https://lopcommerceb2c.onmicrosoft.com/api-inter-opco',
            clientSecret: 'O1Uh~LrAJkJd.q4182-2~V9glYAa~4T0q.',
            baseUrl: 'https://api-apprentissage.lopcommerce-recette.com',
            testApiKey: '6f1d3ca6c2bb41818da7ee370d1aca7ae8c264e6ed884dbc9e601b10d07820b8'
          }
        },
        {
          siren: 849813852,
          name: "OPCO 2i",
          cfaDock: {
            clientId: 'ILABS-Qualifop',
            clientSecret: 'lki~#[|#{[|#{9²&Pv9DzT$c8?z99_%9_%%c2T8-9{YE7m(K',
            baseUrl: 'https://recette-apicfa.opco2i.fr/api',
            testApiKey: 'ac60ac22-8565-4d09-b643-1efee765ec92'
          }
        }
      ],
      cerfa10103Validations: [
        {id: 1, name: 'Validation du formulaire', dependsOn: null}, // vérifier tous les champs obligatoires (non entreprise)
        {id: 2, name: 'Envoi à l\'entreprise', dependsOn: 1},
        {id: 3, name: 'Validation des données saisies par l\'entreprise', dependsOn: 2}, // vérifier les champs entreprise
        {id: 4, name: 'Vérification des signatures (entreprise, étudiant, CFA)', dependsOn: 3}, // vérifier les champs signature
      ],
      cerfa10103ModesContractuels: [
        {id: 1, name: "A durée limitée"},
        {id: 2, name: "Dans le cadre d’un CDI"},
        {id: 3, name: "Entreprise de travail temporaire"},
        {id: 4, name: "Activités saisonnières à deux employeurs"},
      ],
      cerfa10103TypesContrat: [
        {id: 11, name: "Premier contrat d'apprentissage de l'apprenti"},
        {id: 21, isContratRelated: true, name: "Nouveau contrat avec un apprenti qui a terminé son précédent contrat auprès d’un même employeur"},
        {id: 22, isContratRelated: true, name: "Nouveau contrat avec un apprenti qui a terminé son précédent contrat auprès d’un autre employeur"},
        {id: 23, isContratRelated: true, name: "Nouveau contrat avec un apprenti dont le précédent contrat auprès d’un autre employeur a été rompu"},
        {id: 31, isContratRelated: true, isAvenant: true, name: "Modification de la situation juridique de l’employeur"},
        {id: 32, isContratRelated: true, isAvenant: true, name: "Changement d’employeur dans le cadre d’un contrat saisonnier"},
        {id: 33, isContratRelated: true, isAvenant: true, name: "Prolongation du contrat suite à un échec à l’examen de l’apprenti"},
        {id: 34, isContratRelated: true, isAvenant: true, name: "Prolongation du contrat suite à la reconnaissance de l’apprenti comme travailleur handicapé"},
        {id: 35, isContratRelated: true, isAvenant: true, name: "Modification du diplôme préparé par l’apprenti"},
        {id: 36, isContratRelated: true, isAvenant: true, name: "Autres changements : changement de maître d’apprentissage, de durée de travail hebdomadaire, réduction de durée, etc"},
        {id: 37, isContratRelated: true, isAvenant: true, name: "Modification du lieu d’exécution du contrat"},
      ],
      cerfa10103Derogations: [
        {id: 11, name: "Age de l’apprenti inférieur à 16 ans"},
        {id: 12, name: "Age supérieur à 29 ans : cas spécifiques prévus dans le code du travail"},
        {id: 21, name: "Réduction de la durée du contrat ou de la période d’apprentissage"},
        {id: 22, name: "Allongement de la durée du contrat ou de la période d’apprentissage"},
        {id: 50, name: "Cumul de dérogations"},
        {id: 60, name: "Autre dérogation"},
      ],
      cerfa10103Nationalites: [
        {id: 1, name: "Française"},
        {id: 2, name: "Union Européenne"},
        {id: 3, name: "Etranger hors Union Européenne"},
      ],
      cerfa10103RegimesSociaux: [
        {id: 1, name: "MSA"},
        {id: 2, name: "URSSAF"},
      ],
      cerfa10103Situations: [
        {id: 4, name: "Contrat d'apprentissage"},
        {id: 5, name: "Contrat de professionnalisation"},
        {id: 6, name: "Contrat aidé"},
        {id: 7, name: "En formation, au CFA, avant signature d’un contrat d’apprentissage (L6222-12-1 du code du travail)"},
        {id: 8, name: "En formation, au CFA, sans contrat, suite à rupture (5° de L6231-2 du code du travail)"},
        {id: 3, name: "Etudiant"},
        {id: 12, name: "Inactif"},
        {id: 2, name: "Prépa apprentissage"},
        {id: 11, name: "Personne à la recherche d’un emploi (inscrite ou non au Pôle Emploi)"},
        {id: 10, name: "Salarié"},
        {id: 1, name: "Scolaire"},
        {id: 9, name: "Stagiaire de la formation professionnelle"},
      ],
      cerfa10103TypesDiplomes: [
        {id: 80, name: "Doctorat"},
        {id: 71, name: "Master professionnel/DESS"},
        {id: 72, name: "Master recherche/DEA"},
        {id: 73, name: "Master indifférencié"},
        {id: 74, name: "Diplôme d'ingénieur, diplôme d'école de commerce"},
        {id: 79, name: "Autre diplôme ou titre de niveau bac+5 ou plus"},
        {id: 61, name: "1ère année de Master"},
        {id: 62, name: "Licence professionnelle"},
        {id: 63, name: "Licence générale"},
        {id: 69, name: "Autre diplôme ou titre de niveau bac +3 ou 4"},
        {id: 54, name: "Brevet de Technicien Supérieur"},
        {id: 55, name: "Diplôme Universitaire de technologie"},
        {id: 58, name: "Autre diplôme ou titre de niveau bac+2"},
        {id: 41, name: "Baccalauréat professionnel"},
        {id: 42, name: "Baccalauréat général"},
        {id: 43, name: "Baccalauréat technologique"},
        {id: 49, name: "Autre diplôme ou titre de niveau bac"},
        {id: 33, name: "CAP"},
        {id: 34, name: "BEP"},
        {id: 35, name: "Mention complémentaire"},
        {id: 38, name: "Autre diplôme ou titre de niveau CAP/BEP"},
        {id: 25, name: "Diplôme national du Brevet (DNB)"},
        {id: 26, name: "Certificat de formation générale"},
        {id: 13, name: "Aucun diplôme ni titre professionnel"},
      ],
      cerfa10103DerniereClasse: [
        {id: 1, name: "L’apprenti a suivi la dernière année du cycle de formation et a obtenu le diplôme ou titre"},
        {id: 11, name: "L’apprenti a suivi la 1ère année du cycle et l’a validée (examens réussis mais année non diplômante)"},
        {id: 12, name: "L'apprenti a suivi la 1ère année du cycle mais ne l’a pas validée (échec aux examens, interruption ou abandon de formation)"},
        {id: 21, name: "L'apprenti a suivi la 2è année du cycle et l’a validée (examens réussis mais année non diplômante)"},
        {id: 22, name: "L'apprenti a suivi la 2è année du cycle mais ne l’a pas validée (échec aux examens, interruption ou abandon de formation)"},
        {id: 31, name: "L'apprenti a suivi la 3è année du cycle et l’a validée (examens réussis mais année non diplômante, cycle adapté)"},
        {id: 32, name: "L'apprenti a suivi la 3è année du cycle mais ne l’a pas validée (échec aux examens, interruption ou abandon de formation)"},
        {id: 40, name: "L'apprenti a achevé le 1er cycle de l’enseignement secondaire (collège)"},
        {id: 41, name: "L'apprenti a interrompu ses études en classe de 3e"},
        {id: 42, name: "L'apprenti a interrompu ses études en classe de 4e"},
      ],
      cerfa10103TypesEmployeur: [
        {id: 11, name: "Entreprise inscrite au répertoire des métiers ou au registre des entreprises pour l’Alsace-Moselle"},
        {id: 12, name: "Entreprise inscrite uniquement au registre du commerce et des sociétés"},
        {id: 13, name: "Entreprises dont les salariés relèvent de la mutualité sociale agricole"},
        {id: 14, name: "Profession libérale"},
        {id: 15, name: "Association"},
        {id: 16, name: "Autre employeur privé"},
        {id: 21, name: "Service de l’Etat (administrations centrales et leurs services déconcentrés de la fonction publique d’Etat)"},
        {id: 22, name: "Commune"},
        {id: 23, name: "Département"},
        {id: 24, name: "Région"},
        {id: 25, name: "Etablissement public hospitalier"},
        {id: 26, name: "Etablissement public local d’enseignement"},
        {id: 27, name: "Etablissement public administratif de l’Etat"},
        {id: 28, name: "Etablissement public administratif local (y compris établissement public de coopération intercommunale EPCI)"},
        {id: 29, name: "Autre employeur public"},
      ],
      cerfa10103EmployeurSpecifique: [
        {id: 1, name: "Entreprise de travail temporaire"},
        {id: 2, name: "Groupement d’employeurs"},
        {id: 3, name: "Employeur saisonnier"},
        {id: 4, name: "Apprentissage familial : l’employeur est un ascendant de l’apprenti"},
        {id: 0, name: "Aucun de ces cas"},
      ],
      resultatExamen: [
        {id: null, name: null},
        {id: '4', name: 'ABANDON'},
        {id: '1', name: 'ACQUIS'},
        {id: '6', name: 'EXCLUSION'},
        {id: '2', name: 'PARTIELLEMENT ACQUIS'},
        {id: '3', name: 'NON ACQUIS'},
        {id: '5', name: 'NON PRÉSENTÉ'},
      ],
    antenneDiplomeColumns: [
      {class: 'text-center', label: '', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'Diplôme', show_on_diplome: true, show_on_antenne: true, show_for_action: false},
      {class: 'text-center', label: 'Action', show_on_diplome: false, show_on_antenne: false, show_for_action: true},
      {class: 'text-center', label: 'UFA', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'Lieu de formation', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'Nom complémentaire', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'N° habiltation DRAJES', show_on_diplome: true, show_on_antenne: true, show_for_action: false},
      {class: 'text-center', label: 'N° session', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'N° examen DREET', show_on_diplome: true, show_on_antenne: true, show_for_action: false},
      {class: 'text-center', label: 'Début Formation', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'Fin Formation', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'Heures centre', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'Heures en entreprise', show_on_diplome: true, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: 'Documents à télécharger', show_on_diplome: false, show_on_antenne: true, show_for_action: true},
      {class: 'text-center', label: '', show_on_diplome: false, show_on_antenne: true, show_for_action: true},
    ],
    antenneDiplomeStudentsFirstNestColumns: [
      // {colspan: 1, class: 'text-center ', label: '', show_on_antenne: true, show_on_diplome: true},
      {colspan: 9, class: 'text-center border-left', label: 'Apprenant', show_on_antenne: true, show_on_diplome: false},
      {colspan: 8, class: 'text-center', label: 'Apprenant', show_on_antenne: false, show_on_diplome: true},
      {colspan: 2, class: 'text-center border-left', label: 'Contrat', show_on_antenne: true, show_on_diplome: true},
      {colspan: 3, class: 'text-center border-left', label: 'Entreprise', show_on_antenne: true, show_on_diplome: true},
      {colspan: 3, class: 'text-center border-left', label: 'MA ou tuteur', show_on_antenne: true, show_on_diplome: true},
    ],
    antenneDiplomeStudentsSecondNestColumns: [
      {class: 'text-center', label: '', show_on_diplome: false},
      {class: 'text-center border-left', label: 'Nom', show_on_diplome: true},
      {class: 'text-center', label: 'Prénom', show_on_diplome: true},
      {class: 'text-center', label: 'Téléphone', show_on_diplome: true},
      {class: 'text-center', label: 'Mail', show_on_diplome: true},
      {class: 'text-center', label: 'Né le', show_on_diplome: true},
      {class: 'text-center', label: 'statut', show_on_diplome: true},
      {class: 'text-center', label: 'Du', show_on_diplome: true},
      {class: 'text-center', label: 'Au', show_on_diplome: true},
      {class: 'text-center border-left', label: 'Du', show_on_diplome: true},
      {class: 'text-center', label: 'Au', show_on_diplome: true},
      {class: 'text-center border-left', label: 'Nom', show_on_diplome: true},
      {class: 'text-center', label: 'Téléphone', show_on_diplome: true},
      {class: 'text-center', label: 'Mail', show_on_diplome: true},
      {class: 'text-center border-left', label: 'Nom', show_on_diplome: true},
      {class: 'text-center', label: 'Téléphone', show_on_diplome: true},
      {class: 'text-center', label: 'Mail', show_on_diplome: true},
    ],
    etatEntiteGlobale: [
      { id: 0, name: 'actif', show_etat: true },
      { id: 1, name: 'archivé', show_etat: true },
      { id: 2, name: 'supprimé', show_etat: false },
    ],
    typeDiplome: [
      { id: null, name: 'Tous'},
      { id: 0, name: 'CPJEPS'},
      { id: 1, name: 'BPJEPS'},
      { id: 2, name: 'DEJEPS'},
      { id: 3, name: 'DESJEPS'},
      { id: 4, name: 'Titre Pro'},
      { id: 5, name: 'Titre à Finalité'},
      { id: 5, name: 'Certificat de Qualification Professionnelle (CQP)'}
    ],
    statusSurvey: [
      { id: 1, name: "en attente de réponse" },
      { id: 2, name: "répondu" }
    ],
    typeReponseEnqueteQualite: [
      { id: null, name: 'Pas de réponse', show_on_form: false },
      { id: 1, name: 'Insatisfaisant', show_on_form: true },
      { id: 2, name: 'Tout juste satisfaisant', show_on_form: true },
      { id: 3, name: 'Satisfaisant', show_on_form: true },
      { id: 4, name: 'Très satisfaisant', show_on_form: true },
    ],
    typeEnqueteQualite: [
      { id: 0, name: 'Email automatique', show_on_form: false },
      { id: 1, name: 'Email', show_on_form: true },
      { id: 2, name: 'Téléphonique', show_on_form: true },
      { id: 3, name: 'Autre', show_on_form: true }
    ],
    niveauDiplome: [
      { id: null, name: "Non renseigné" },
      { id: 1, name: "Même niveau de diplôme" },
      { id: 2, name: "Niveau de diplôme supérieur" },
    ],
    tempsImmersionEtranger: [
      { id: null, name: "Non renseigné" },
      { id: 1, name: "Moins de 3 mois" },
      { id: 2, name: "Entre 3 et 6 mois" },
      { id: 3, name: "Plus de 6 mois" },
      { id: 4, name: "De manière définitive" },
    ],
    situationSortieEtudiant: [
      { id: 2, name: "En emploi" },
      { id: 3, name: "En formation" },
      { id: 4, name: "En recherche d'emploi" },
      { id: 5, name: "En Immersion à l'étranger" },
      { id: 6, name: "Autres" },
    ],
    regionExerciceActivite: [
      { id: "Auvergne Rhône-Alpes", name: "Auvergne Rhône-Alpes" },
      { id: "Provence Alpes Côte d’Azur", name: "Provence Alpes Côte d’Azur" },
      { id: "Autres régions", name: "Autres régions" },
      { id: "A l’étranger", name: "A l’étranger" },
    ],
    regionMondeImmersion: [
      { id: "Pays européens", name: "Pays européens" },
      { id: "Amériques", name: "Amériques" },
      { id: "Asie", name: "Asie" },
      { id: "Afrique", name: "Afrique" },
      { id: "Autre", name: "Autre" },
    ],
    secteurActivite: [
      { id: "Sport", name: "Sport" },
      { id: "Animation", name: "Animation" },
      { id: "Tourisme", name: "Tourisme" },
      { id: "ESports", name: "ESports" },
      { id: "Autre", name: "Autre" },
    ],
    tempsAlEtranger: [
      { id: 1, name: "Moins de 3 mois" },
      { id: 2, name: "Entre 3 et 6 mois" },
      { id: 3, name: "Plus de 6 mois" },
      { id: 4, name: "De manière définitive" },
    ],
    // * Si tu change les types de la facture ici
    // * Il faut que tu les change aussi dans l'entitée EtudiantDocumentFacture
    typeFactures: [
      {id: 5, name: "Prise en charge aide a la mobilité européenne"},
      {id: 6, name: "Prise en charge aide au permis"},
      {id: 1, name: "Prise en charge cout pedagogique"},
      {id: 2, name: "Prise en charge frais d'équipement"},
      {id: 4, name: "Prise en charge frais d'hébergement"},
      {id: 3, name: "Prise en charge frais de restauration"},
      {id: 7, name: "Autre"},
    ],
    // * Si tu change les details du type de la facture ici
    // * Il faut que tu les change aussi dans l'entitée EtudiantDocumentFacture
    typeFactureDetails: [
      {id: 8, type: 2, name: "Pack informatique",               tag: 'pack_informatique'},
      {id: 9, type: 2, name: "Pack matériel",                   tag: 'pack_materiel'},
      {id: 1, type: 1, name: "Pack pédagogique 1er acompte",    tag: 'pack_informatique_1er_acompte'},
      {id: 2, type: 1, name: "Pack pédagogique 2ème acompte",   tag: 'pack_informatique_2eme_acompte'},
      {id: 3, type: 1, name: "Pack pédagogique 3ème acompte",   tag: 'pack_informatique_3eme_acompte'},
      {id: 4, type: 1, name: "Pack pédagogique 4ème acompte",   tag: 'pack_informatique_4eme_acompte'},
      {id: 5, type: 1, name: "Pack pédagogique 5ème acompte",   tag: 'pack_informatique_5eme_acompte'},
      {id: 6, type: 1, name: "Pack pédagogique 6ème acompte",   tag: 'pack_informatique_6eme_acompte'},
      {id: 7, type: 1, name: "Solde - Avoir",                   tag: 'solde-avoir'}
    ],
    resultatEvaluation: [
      {id: null, name: 'Non renseigné'},
      {id: 0, name: 'Non évalué'},
      {id: 1, name: 'Acquis'},
      {id: 2, name: 'En Cours'},
      {id: 3, name: 'Non Acquis'},
    ],
    typeOriginCandidature: [
      {id: null, name: 'Non renseigné'},
      {id: 0, name: 'Parcoursup'},
      {id: 1, name: 'Site internet'},
      {id: 2, name: 'Réseaux Sociaux'},
      {id: 3, name: 'Autre'},
    ],
    typeOriginTEP: [
      {id: null, name: 'Non renseigné'},
      {id: 1, name: 'Validé'},
      {id: 2, name: 'Dispensé'},
    ],
    typeFraisConvention: [
      {id: 0, name: 'Formateur', short_list: false, order: 1},
      {id: 1, name: 'Jury', short_list: false, order: 2},
      {id: 2, name: 'Coordination', short_list: false, order: 3},
      {id: 3, name: 'Administratif', short_list: false, order: 4},
      {id: 4, name: 'Divers', short_list: false, order: 5},
      {id: 5, name: 'Frais de formation', short_list: true, order: 1},
      {id: 6, name: 'Frais pédagogiques', short_list: true, order: 2},
    ],
    typeConvention: [
      { id: 0, name: 'Convention'},
      { id: 1, name: 'Avenant'}
    ],
    gedDocumentType: [
      {id: null, name: 'Non renseigné'},
      {id: 1, name: 'Process'},
      {id: 2, name: 'Formulaire'},
    ],
    gedDocumentStatut: [
      {id: null, name: 'Non renseigné'},
      {id: 1, name: 'Dernière version'},
      {id: 2, name: 'Version à modifier'},
    ],
    typePrestataires: [
      { id: null, name: "Non renseigné" },
      { id: 6, name: "Prestataires d’actions de Formation" },
      { id: 1, name: "Prestataires d’actions de Formation en sous-traitance" },
      { id: 2, name: "Prestataires Porteurs d’Affaires" },
      { id: 3, name: "Prestataires Loueurs d’espace (salle de classe – plateaux techniques)" },
      { id: 4, name: "Prestataires Administratif" },
      { id: 5, name: "Prestataires Communication-Evènementiel" },
    ],
    statutPrestataire: [
      { id: null, name: 'Non renseigné', color: 'grey' },
      { id: 1, name: 'Salarié', color: 'blue' },
      { id: 2, name: 'Indépendant', color: 'orange' },
      { id: 3, name: 'Retraité', color: 'blue-grey' },
      { id: 4, name: 'Autre', color: 'grey' },
    ],
    missionPrestataire: [
      { id: null, name: 'Non renseigné' },
      { id: 1, name: 'Formateur' },
      { id: 2, name: 'Tuteur' },
      { id: 3, name: 'Jury' },
      { id: 4, name: 'Évaluateur' },
    ],
    statutRattachementPrestataireEntreprise: [
      { id: null, name: 'Non renseigné' },
      { id: 1, name: 'Gérant' },
      { id: 2, name: 'Associé' },
      { id: 3, name: 'Salarié' },
      { id: 4, name: 'Bénévole' },
      { id: 5, name: 'Administrateur' },
    ],
    certificationQualiopi: [
      { id: null, name: "Non renseigné" },
      { id: 1, name: "VAE (validation des acquis de l’expérience)" },
      { id: 2, name: "Bilan de compétences" },
      { id: 3, name: "Actions de formation" },
      { id: 4, name: "Actions de formation par l’apprentissage" },
    ],
    documentsFormateur: [
      { id: 1, name: "Fiche administrative", description: "", expires: false, tag: "fiche_administrative" },
      { id: 2, name: "CV", description: "", expires: false, tag: "cv" },
      { id: 3, name: "Copie pièce d’identité", description: "", expires: false, tag: "piece_identite" },
      { id: 4, name: "Copie dernier diplôme", description: "en lien avec vos interventions au CFA", expires: false, tag: "dernier_diplome" },
      { id: 5, name: "Carte professionnelle", description: "( le cas échéant), N°", expires: true, tag: "carte_pro" },
      { id: 6, name: "Extrait de casier judiciaire N°3", description: "(datant de moins d’un mois à la date d’entrée)", expires: false, tag: "extrait_casier_3" },
      { id: 7, name: "Charte d’engagement formateur FuturOsud-FEA", description: "", expires: false, tag: "charte_engagement" },
      { id: 8, name: "Attestation URSSAF", description: "", expires: true, tag: "attestation_vigilance" },
    ],
    documentsEntrepriseRattachement: [
      { id: 1, name: "Relevé d’identité bancaire (RIB ou IBAN)", of: false, expires: false, tag: "rib" },
      { id: 2, name: "Avis de déclaration INSEE/ Kbis", of: false, expires: true, tag: "kbis" },
      { id: 3, name: "Attestation de déclaration et de paiement des cotisations et contributions de sécurité sociale", of: false, expires: true, tag: "attestation_declaration" },
      { id: 4, name: "Assurance pro", of: false, expires: false, tag: "assurance_pro" },
      { id: 5, name: "Attestation DREETS", of: true, expires: true, tag: "attestation_dreets" },
      { id: 6, name: "Certification Qualiopi", of: true, expires: true, tag: "certification_qualiopi" },
      { id: 7, name: "Agrément pour formation", of: true, expires: false, tag: "agrement_formation" },
      { id: 8, name: "Attestation URSSAF", of: true, expires: true, tag: "attestation_vigilance" },
      { id: 9, name: "Conditions Générales de Vente", of: false, expires: false, tag: "cgv"}
    ],
    regionsFrancaises: [
      { id: null, code_insee: "", name: "Non renseigné", diminutive: "" },
      { id: 1, code_insee: "84", name: "Auvergne-Rhône-Alpes", diminutive: "aura" },
      { id: 2, code_insee: "27", name: "Bourgogne-Franche-Comté", diminutive: "bfc" },
      { id: 3, code_insee: "53", name: "Bretagne", diminutive: "bre" },
      { id: 4, code_insee: "24", name: "Centre-Val de Loire", diminutive: "cvdl" },
      { id: 5, code_insee: "94", name: "Corse", diminutive: "crs" },
      { id: 6, code_insee: "44", name: "Grand-Est", diminutive: "ge" },
      { id: 7, code_insee: "01", name: "Guadeloupe", diminutive: "gua" },
      { id: 8, code_insee: "03", name: "Guyane", diminutive: "guy" },
      { id: 9, code_insee: "32", name: "Hauts-de-France", diminutive: "hdf" },
      { id: 10, code_insee: "11", name: "Île-de-France", diminutive: "idf" },
      { id: 11, code_insee: "04", name: "La Réunion", diminutive: "run" },
      { id: 12, code_insee: "02", name: "Martinique", diminutive: "mar" },
      { id: 13, code_insee: "06", name: "Mayotte", diminutive: "may" },
      { id: 14, code_insee: "28", name: "Normandie", diminutive: "no" },
      { id: 15, code_insee: "75", name: "Nouvelle-Aquitaine", diminutive: "na" },
      { id: 16, code_insee: "76", name: "Occitanie", diminutive: "occ" },
      { id: 17, code_insee: "52", name: "Pays de la Loire", diminutive: "pdl" },
      { id: 18, code_insee: "93", name: "Provence-Alpes-Côte d'Azur", diminutive: "paca" }
    ],
    typeAction: [
      {id: null, name: 'Non renseigné'},
      {id: 1, name: 'Formation diplômante'},
      {id: 2, name: 'Formation non diplômante'},
    ],
    typeFormateur: [
      // {id: null, name: 'Non renseigné'},
      {id: 1, name: 'Intervenant interne'},
      {id: 2, name: 'Intervenant externe'},
      {id: 3, name: 'Autre intervenant'},
    ],
    objectifsPedagogiques : [
      {id: 0, name: 'Non renseigné'},
      {id: 1, name: 'Découvertes des métiers et de l\'entreprise'},
      {id: 2, name: 'Découverte du projet professionel'},
      {id: 3, name: 'Elaboration du projet professionel'},
      {id: 4, name: 'Confirmation du projet professionel'},
      {id: 5, name: 'Perspectives d\'emploi'},
    ],
    entrepriseSuiviType: [
      {id: 1, name: 'Autre'},
      // {id: 2, name: 'Mise à disposition de matériel'},
      {id: 3, name: 'Mise en place du partenariat'},
      {id: 4, name: 'Première rencontre'},
      {id: 5, name: 'Rendez-vous avec le chef d\'entreprise'},
      {id: 6, name: 'Signature du partenariat'},
      {id: 7, name: 'Suivis et rencontres avec le CFA'},
    ],
    entreprisePartenariatType: [
      {name: 'Action de citoyenneté'},
      {name: 'Adhérent'},
      {name: 'Aucun Partenariat'},
      {name: 'Charte de partenariat'},
      {name: 'Coaching'},
      {name: 'Convenion de partenariat'},
      {name: 'Emploi'},
      {name: 'Présentation métier'},
      {name: 'Prospection'},
      {name: 'Soutien financier/mécénat'},
      {name: 'Stage'},
      {name: 'Taxe apprentissage'},
      {name: 'Visite entreprise'},
    ],
    // *** OPCO ***
    diplomesEtTitresApprenti: [
      { id: 80, name: "80: Doctorat" },
      { id: 71, name: "71: Master professionnel/DESS" },
      { id: 72, name: "72: Master recherche/DEA" },
      { id: 73, name: "73: Master indifférencié" },
      { id: 74, name: "74: Diplôme d'ingénieur, diplôme d'école de commerce" },
      { id: 79, name: "79: Autre diplôme ou titre de niveau bac+5 ou plus" },
      { id: 61, name: "61: 1ère année de Master" },
      { id: 62, name: "62: Licence professionnelle" },
      { id: 63, name: "63: Licence générale" },
      { id: 64, name: "64: Bachelor Universitaire de technologie BUT" },
      { id: 69, name: "69: Autre diplôme ou titre de niveau bac +3 ou 4" },
      { id: 54, name: "54: Brevet de Technicien Supérieur" },
      { id: 55, name: "55: Diplôme Universitaire de technologie" },
      { id: 58, name: "58: Autre diplôme ou titre de niveau bac+2" },
      { id: 41, name: "41: Baccalauréat professionnel" },
      { id: 42, name: "42: Baccalauréat général" },
      { id: 43, name: "43: Baccalauréat technologique" },
      { id: 49, name: "49: Autre diplôme ou titre de niveau bac" },
      { id: 33, name: "33: CAP" },
      { id: 34, name: "34: BEP" },
      { id: 35, name: "35: Mention complémentaire" },
      { id: 38, name: "38: Autre diplôme ou titre de niveau CAP/BEP" },
      { id: 25, name: "25: Diplôme national du Brevet" },
      { id: 26, name: "26: Certificat de formation générale" },
      { id: 13, name: "13: Aucun diplôme ni titre professionnel" }
    ],
    derniereAnneeOuClasseSuivieApprenti: [
      { id: 1, name: "1: L’apprenti a suivi la dernière année du cycle de formation et a obtenu le diplôme ou titre" },
      { id: 11, name: "11: L’apprenti a suivi la 1ère année du cycle et l’a validée (examens réussis mais année non diplômante)" },
      { id: 12, name: "12: L’apprenti a suivi la 1ère année du cycle mais ne l’a pas validée (échec aux examens, interruption ou abandon de formation)" },
      { id: 21, name: "21: L’apprenti a suivi la 2è année du cycle et l’a validée (examens réussis mais année non diplômante)" },
      { id: 22, name: "22: L’apprenti a suivi la 2è année du cycle mais ne l’a pas validée (échec aux examens, interruption or abandon de formation)" },
      { id: 31, name: "31: L’apprenti a suivi la 3è année du cycle et l’a validée (examens réussis mais année non diplômante, cycle adaptés)" },
      { id: 32, name: "32: L’apprenti a suivi la 3è année du cycle mais ne l’a pas validée (échec aux examens, interruption ou abandon de formation)" },
      { id: 40, name: "40: L’apprenti a achevé le 1er cycle de l’enseignement secondaire (collège)" },
      { id: 41, name: "41: L’apprenti a interrompu ses études en classe de 3è" },
      { id: 42, name: "42: L’apprenti a interrompu ses études en classe de 4è" }
    ],
    situationAvantContrat: [
      { id: 1, name: "1: Scolaire" },
      { id: 2, name: "2: Prépa apprentissage" },
      { id: 3, name: "3: Etudiant" },
      { id: 4, name: "4: Contrat d’apprentissage" },
      { id: 5, name: "5: Contrat de professionnalisation" },
      { id: 6, name: "6: Contrat aidé" },
      { id: 7, name: "7: En formation au CFA sous statut de stagiaire de la formation professionnelle, avant conclusion d’un contrat d’apprentissage (L6222-12-1 du code du travail)" },
      { id: 8, name: "8: En formation, au CFA sans contrat sous statut de stagiaire de la formation professionnelle, à la suite d’une rupture d’un précédent contrat (5° de L6231-2 du code du travail)" },
      { id: 9, name: "9: Autres situations sous statut de stagiaire de la formation professionnelle" },
      { id: 10, name: "10: Salarié" },
      { id: 11, name: "11: Personne à la recherche d’un emploi (inscrite ou non à Pôle Emploi)" },
      { id: 12, name: "12: Inactif" }
    ],
    // *** FIN OPCO ***
  },

  mutations: {
    addTicket (state, ticket) {
      state.tickets.unshift(ticket)
    },
    setLoading (state, loading) {
      state.loading = loading
    }
  },

  getters: {
    getBackgrounds: state => function () {
      return state.backgrounds
    },
    getFormesJuridiques: state => function () {
      return state.formesJuridiques
    },
    getTypesEntreprises: state => function () {
      return state.typesEntreprises
    },
    getActivities: state => function () {
      return state.activities
    },
    getColor: state => function (type) {
      return state.colors[type]
    },
    getCurrentUser: state => function (type) {
      return state.currentUser
    },
    getRoles: state => function (type) {
      return state.roles
    },
    getTickets: state => function (type) {
      return state.tickets
    },
    getLocales: state => function (type) {
      return state.locales
    },
    getTypesDocuments: state => function (type) {
      return state.typeDocuments
    },
    getTypeExports: state => function (type) {
      return state.typeExports
    },
    getDepartements: state => function (type) {
      return state.departements
    },
    getPays: state => function (type) {
      return state.pays
    },
    getCivilites: state => function (type) {
      return state.civilites
    },
    getSituationMatrimoniale: state => function($type) {
      return state.situationMatrimoniale
    },
    getTypeCursus: state => function($type) {
      return state.typeCursus
    },
    getNiveauDeFormation: state => function (type) {
      return state.niveauDeFormation
    },
    getSituationEtudiant: state => function (type) {
      return state.situationEtudiant
    },
    getFinancement: state => function (type) {
      return state.financement
    },
    getOpcoDetails: state => function (type) {
      return state.opcoDetails
    },
    getListeOpcos: state => function (type) {
      return state.listeOpcos
    },
    getListeFinancementAutre: state => function (type) {
      return state.listeFinancementAutre
    },
    getStatutEtudiant: state => function (type) {
      return state.statutEtudiant
    },
    getOptionSalarie: state => function (type) {
      return state.optionSalarie
    },
    getOptionsYesNo: state => function (type) {
      return state.optionsYesNo
    },
    getOptionsYesNoBool: state => function (type) {
      return state.optionsYesNoBool
    },
    getOptionsTypeSession: state => function (type) {
      return state.optionsTypeSession
    },
    getOptionsTrueFalseToYesNo: state => function (type) {
      return state.optionsTrueFalseToYesNo
    },
    getOptionsNiveauDiplome: state => function (type) {
      return state.optionsNiveauDiplome
    },
    getNomenclatureNiveauDiplome: state => function (type) {
      return state.nomenclatureNiveauDiplome
    },
    getOptionsCandidatureRefusMotif: state => function (type) {
      return state.optionsRefusMotif
    },
    getOptionsGroupColor: state => function (type) {
      return state.optionsGroupColor
    },
    getUtilisateurRole: state => function (type) {
      return state.utilisateurRole
    },
    getInterviewsType: state => function (type) {
      return state.interviewsType
    },
    getInterviewsTypeDetails: state => function (type) {
      return state.interviewsTypeDetails
    },
    getInterviewMode: state => function (type) {
      return state.interviewMode
    },
    getRaisonAbsence: state => function (type) {
      return state.raisonAbsence
    },
    getSuivisSteps: state => function (type) {
      return state.suivisSteps
    },
    getContrats: state => function (type) {
      return state.contrats
    },
    getSituations: state => function (type) {
      return state.situations
    },
    getTypesContrats: state => function (type) {
      return state.typesContrats
    },
    getDiplomeDurations: state => function (type) {
      return state.diplomeDurations
    },
    getEntreprisePartenaire: state => function () {
      return state.entreprisePartenaire
    },
    getGroupeYear: state => function (type) {
      return state.groupeYear
    },
    getYearsClosing: state => function (type) {
      return state.yearClosing
    },
    getTicketModule: state => function (type) {
      return state.ticketModule
    },
    getTicketType: state => function (type) {
      return state.ticketType
    },
    getTicketStatus: state => function (type) {
      return state.ticketStatus
    },
    getEntrepriseClosed: state => function (type) {
      return state.entrepriseClosed
    },
    getEntrepriseTaille: state => function (type) {
      return state.entrepriseTaille
    },
    getStagiaireEtat: state => function (type) {
      return state.stagiaireEtat
    },
    getStagiaireEtatParours: state => function (type) {
      return state.stagiaireEtatParours
    },
    getSortieMotifStagiaire: state => function (type) {
      return state.sortieMotifStagiaire
    },
    getTypeCandidature: state => function (type) {
      return state.typeCandidature
    },
    getCerfa10103Validations: state => function (type) {
      return state.cerfa10103Validations
    },
    getCerfa10103ModesContractuels: state => function (type) {
      return state.cerfa10103ModesContractuels
    },
    getCerfa10103TypesContrat: state => function (type) {
      return state.cerfa10103TypesContrat
    },
    getCerfa10103Derogations: state => function (type) {
      return state.cerfa10103Derogations
    },
    getCerfa10103Nationalites: state => function (type) {
      return state.cerfa10103Nationalites
    },
    getCerfa10103RegimesSociaux: state => function (type) {
      return state.cerfa10103RegimesSociaux
    },
    getCerfa10103Situations: state => function (type) {
      return state.cerfa10103Situations
    },
    getCerfa10103TypesDiplomes: state => function (type) {
      return state.cerfa10103TypesDiplomes
    },
    getCerfa10103DerniereClasse: state => function (type) {
      return state.cerfa10103DerniereClasse
    },
    getCerfa10103TypesEmployeur: state => function (type) {
      return state.cerfa10103TypesEmployeur
    },
    getCerfa10103EmployeurSpecifique: state => function (type) {
      return state.cerfa10103EmployeurSpecifique
    },
    getResultatExamen: state => function (type) {
      return state.resultatExamen
    },
    getAntenneDiplomeColumns: state => function (type) {
      return state.antenneDiplomeColumns
    },
    getAntenneDiplomeStudentsFirstNestColumns: state => function (type) {
      return state.antenneDiplomeStudentsFirstNestColumns
    },
    getAntenneDiplomeStudentsSecondNestColumns: state => function (type) {
      return state.antenneDiplomeStudentsSecondNestColumns
    },
    getEtatEntiteGlobale: state => function (type) {
      return state.etatEntiteGlobale
    },
    getTypeDiplome: state => function (type) {
      return state.typeDiplome
    },
    getStatusSurvey: state => function (type) {
      return state.statusSurvey
    },
    getTypeReponseEnqueteQualite: state => function (type) {
      return state.typeReponseEnqueteQualite
    },
    getTypeEnqueteQualite: state => function (type) {
      return state.typeEnqueteQualite
    },
    getNiveauDiplome: state => function (type) {
      return state.niveauDiplome
    },
    getTempsImmersionEtranger: state => function (type) {
      return state.tempsImmersionEtranger
    },
    getSituationSortieEtudiant: state => function (type) {
      return state.situationSortieEtudiant
    },
    getRegionExerciceActivite: state => function (type) {
      return state.regionExerciceActivite
    },
    getRegionMondeImmersion: state => function (type) {
      return state.regionMondeImmersion
    },
    getSecteurActivite: state => function (type) {
      return state.secteurActivite
    },
    getTempsAlEtranger: state => function (type) {
      return state.tempsAlEtranger
    },
    getTypeFactures: state => function($type) {
      return state.typeFactures
    },
    getTypeFactureDetails: state => function(id, type) {
      if(id === undefined && type === undefined)  return state.typeFactureDetails
      const result = state.typeFactureDetails.find(object => object.id === id && object.type === type);
      return result || null; // Si aucun objet ne correspond, renvoie null ou une autre valeur par défaut.
    },
    getResultatEvaluation: state => function (type) {
      return state.resultatEvaluation
    },
    getTypeOriginCandidature: state => function ($type) {
      return state.typeOriginCandidature
    },
    getTypeOriginTEP: state => function ($type) {
      return state.typeOriginTEP
    },
    getTypeFraisConvention: state => function (type) {
      return state.typeFraisConvention
    },
    getTypeConvention: state => function (type) {
      return state.typeConvention
    },
    getGedDocumentType: state => function ($type) {
      return state.gedDocumentType
    },
    getGedDocumentStatut: state => function ($type) {
      return state.gedDocumentStatut
    },
    getTypePrestataires: state => function (type) {
      return state.typePrestataires
    },
    getStatutPrestataire: state => function (type) {
      return state.statutPrestataire
    },
    getMissionPrestataire: state => function (type) {
      return state.missionPrestataire
    },
    getStatutRattachementPrestataireEntreprise: state => function (type) {
      return state.statutRattachementPrestataireEntreprise
    },
    getStatutEntreprise: state => function (type) {
      return state.statutsEntreprise
    },
    getCertificationQualiopi: state => function (type) {
      return state.certificationQualiopi
    },
    getDocumentsFormateur: state => function (type) {
      return state.documentsFormateur
    },
    getDocumentsEntrepriseRattachement: state => function (type) {
      return state.documentsEntrepriseRattachement
    },
    getRegionsFrancaises: state => function (type) {
      return state.regionsFrancaises
    },
    getTypeAction: state => function (type) {
      return state.typeAction
    },
    getTypeFormateur: state => function (type) {
      return state.typeFormateur
    },
    getObjectifsPedagogiques: state => function (type) {
      return state.objectifsPedagogiques
    },
    getEntrepriseSuiviType: state => function (type) {
      return state.entrepriseSuiviType
    },
    getEntreprisePartenariatType: state => function (type) {
      return state.entreprisePartenariatType
    },
    getDiplomesEtTitresApprenti: state => function (type) {
      return state.diplomesEtTitresApprenti
    },
    getDerniereAnneeOuClasseSuivieApprenti: state => function (type) {
      return state.derniereAnneeOuClasseSuivieApprenti
    },
    getSituationAvantContrat: state => function (type) {
      return state.situationAvantContrat
    },
  }
};
