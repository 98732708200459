import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antenneNotifications = {
  state: {
    notifications: [],
    notification: null,
  },
  mutations: {
    setNotifications(state, notifications){
      state.notifications = notifications;
    }
  },
  actions: {
    //Récupération des notifications
    async fetchNotifications(context, data){
      return ApiService.getRequest(data.url + '?page=' + data.index, {}, true).then(
        response => {
          context.commit("setNotifications", response.data)
        }
      ).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récupération des notifications",
          color: 'red'
        })
      })
    },
    // Edit le readAt de lé notification au click
    async editNotifications(context, data){
      try{
        await ApiService.putRequest(data.url, {readAt: data.readAt});
      }catch(error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: 'red'
        })
      }
    }
  },
  getters: {
    getNotifications(state){
      return state.notifications;
    },
  }
}
