import ApiService from '@services/api.service';

export const user = {
  state: {
    users: [],
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
  },
  actions: {
    fetchUsers(context) {
      return ApiService.getRequest('users').then(
        response => {
          context.commit('setUsers', response.data['hydra:member'])
        }
      )
        .catch( error => { console.error(error) } );
    },
    fetchOneUser(context, url) {
      return ApiService.getRequest(url).then(
        response => {
          context.commit('setUsers', response.data)
        }
      )
        .catch( error => { console.error(error) } );
    },
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
  }
}
