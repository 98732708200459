import ApiService from '@services/api.service';
import {Notify} from 'quasar';

export const antenneConvention = {
  state: {
    conventions: [],
    convention: null,
    objet: null
  },
  mutations: {
    setConventions(state, conventions) {
      state.conventions = conventions;
    },
    setConvention(state, convention) {
      state.convention = convention;
    },
    callbackAddDocument(state, payload) {
      state.objet = payload.objet;
    },
    callbackAddSession(state, payload) {
      state.objet = payload.objet;
    },
    callbackAddFee(state, payload) {
      state.objet = payload.objet;
    },
    callbackAddConvention(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditConvention(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditSession(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditFee(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditDocument(state, payload) {
      state.objet = payload.objet;
    },
    callbackdeleteDocument(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditStatusSession(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditStatusFee(state, payload) {
      state.objet = payload.objet;
    },
  },
  actions: {
    /* CONVENTIONS (GET) */
    //  * liste des conventions (filtrée par antenne) - avenants compris
    fetchAntenneConventions(context) {
      return ApiService.getRequest('/antenne_conventions').then(
        response => {
          context.commit('setConventions', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    fetchLightListeAntenneConventions(context) {
      return ApiService.getRequest('/antenne_convention_light_list').then(
        response => {
          context.commit('setConventions', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    //  * liste des conventions (filtrée par antenne) - avenants non compris
    fetchAntenneConventionsWithoutAvenant(context) {
      return ApiService.getRequest('/antenne_conventions?type=0').then(
        response => {
          context.commit('setConventions', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    //FetchConvention pour factures stagiaire
    fetchAntenneConventionStagiaire(context) {
      return ApiService.getRequest('antenne_conventions?properties[]=id&properties[]=etat&properties[]=convention_number', {etat: 0}).then(
        response => {
        context.commit('setConventions', response.data['hydra:member'])
        })
        .catch(error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: 'red'
          })
        })
    },

    /* CRUD CONVENTIONS (POST/PATCH) */
    //  * add convention or avenant
    /*
        - add convention
        - copy collections if exist and delete for serialization
        - transform data collection => dataForm
        - add collections
     */
    async addAntenneConvention({ state, dispatch }, data) {
      const checkIfCollectionIsEmpty = (collection) => (typeof collection === 'object') ? collection && Object.keys(collection).length === 0 : collection && collection.length === 0;
      try {
        // copy and delete collections
        if (checkIfCollectionIsEmpty(data.convention_prices) === false) {
          var convention_prices = { ...data.convention_prices};
          delete data.convention_prices;
        }

        if (checkIfCollectionIsEmpty(data.convention_sessions) === false) {
          var convention_sessions = { ...data.convention_sessions};
          delete data.convention_sessions;
        }

        if (checkIfCollectionIsEmpty(data.documents_convention) === false) {
          var convention_documents = { ...data.documents_convention};
          delete data.documents_convention;
        }

        const response = await ApiService.postRequest('/antenne_conventions', data);
        if (response.status === 201) {
          //  Add collection convention_prices
          if (checkIfCollectionIsEmpty(convention_prices) === false) {
            convention_prices.antenne_convention = response.data['@id'];

            const fees = await dispatch('convertDataFeesToFormData', convention_prices);

            response.data.convention_prices = [{ ...fees}];
          }

          //  Add collection convention_prices
          if (checkIfCollectionIsEmpty(convention_sessions) === false) {
            convention_sessions.antenne_convention = response.data['@id'];

            const sessions = await dispatch('convertDataSessionsToFormData', convention_sessions);

            response.data.convention_sessions = [{ ...sessions}];
          }

          if (checkIfCollectionIsEmpty(convention_documents) === false) {
            convention_documents.antenne_convention = response.data['@id'];

            const documents = await dispatch('convertDataDocumentsToFormData', convention_documents);

            response.data.documents_convention = [{ ...documents}];
          }

          this.commit('callbackAddConvention', response.data);
          let message = 'La convention a été ajoutée avec succès.';
          if (data.type === 1) {
            message = 'L\'avenant a été ajouté avec succès.';
          }

          Notify.create({
            message: message,
            color: 'green'
          });
          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * édition d'une convention ou d'un avenant
    async editAntenneConvention({ state }, data) {
      try {
        const response = await ApiService.patchRequest(`/antenne_conventions/${data.id}`, data);
        if (response.status === 200) {
          this.commit('callbackEditConvention', response.data);

          let message = 'La convention a été éditée avec succès.';
          if (data.type === 1) {
            message = 'L\'avenant a été édité avec succès.'
          }

          Notify.create({
            message: message,
            color: 'green'
          });
          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * modification de l'état de la convention
    async editStatusAntenneConvention({ state }, data) {
      try {
        const response = await ApiService.patchRequest(`/antenne_conventions/${data.id}`, { etat: data.etat });
        if (response.status === 200) {
          this.commit('callbackEditConvention', response.data);
          Notify.create({
            message: data.message,
            color: 'green'
          });
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },

    /* --- COLLECTIONS : FRAIS DE FORMATION / SESSIONS DE CONVENTION / DOCUMENTS */
    /* DOCUMENTS */
    // transféré dans le fichier documents
    // async downloadAntenneConventionDocument(context, uri) {
    //   try {
    //     await ApiService.downloadRequest({ url: uri.substring(1) + '/download' })
    //   } catch (error) {
    //     console.error(error);
    //     Notify.create({
    //       message: "Une erreur s'est produite",
    //       color: "red"
    //     })
    //     return Promise.reject(error);
    //   }
    // },
    async convertDataDocumentsToFormData({ state, dispatch }, data) {
      try {
        let form = new FormData();
        form.append('antenne_convention', data.antenne_convention);
        form.append('name', data.name);
        form.append('file', data.file);
        form.append('date_reception', data.date_reception);

        return await dispatch("addAntenneConventionDocument", form, { root: true })
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async addAntenneConventionDocument({ state }, data ) {
      try {
        const response = await ApiService.postRequest('/antenne_convention_documents', data);
        if (response.status === 201) {
          this.commit('callbackAddDocument', response.data);
          Notify.create({
            message: 'Le document a été ajouté avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editAntenneConventionDocument(context, data) {
      try {
        const response = ApiService.postRequest(`/antenne_convention_documents/${data.uuid}`, data);
        if (response.status === 200) {
          this.commit('callbackEditDocument', response.data);
          Notify.create({
            message: 'Le document a été modifié avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // transféré dans le fichier documents
    // async deleteConventionDocument(context, uri) {
    //   try {
    //     const response = await ApiService.deleteRequest(uri);
    //     if (response.status === 204) {
    //       this.commit('callbackdeleteDocument', response.data);
    //       Notify.create({
    //         message: "Le document a bien été supprimé !",
    //         color: "green"
    //       })
    //       return Promise.resolve();
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     Notify.create({
    //       message: "Une erreur s'est produite",
    //       color: "red"
    //     })
    //     return Promise.reject(error);
    //   }
    // },

    /* SESSIONS DE CONVENTION */
    async convertDataSessionsToFormData({ state, dispatch }, data) {
      try {
        let form = new FormData();
        form.append('antenneConvention', data.antenne_convention);
        form.append('antenne_convention', data.antenne_convention);

        if (data.diplome_session !== null) {
          form.append('diplome_session', data.diplome_session);
        }

        if (data.antenne_secteur !== null) {
          form.append('antenne_secteur', data.antenne_secteur);
        }

        if (data.date_start !== null) {
          form.append('date_start', data.date_start);
        }

        if (data.date_end !== null) {
          form.append('date_end', data.date_end);
        }

        // if (data.am_start !== null) {
        //   form.append('am_start', data.am_start);
        // }
        //
        // if (data.am_end !== null) {
        //   form.append('am_end', data.am_end);
        // }
        //
        // if (data.pm_start !== null) {
        //   form.append('pm_start', data.pm_start);
        // }
        //
        // if (data.pm_end !== null) {
        //   form.append('pm_end', data.pm_end);
        // }

        return await dispatch("addAntenneConventionSession", form, { root: true })
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async addAntenneConventionSession({ state, dispatch }, data) {
      try {
        let antenneConvention = '';
        for (var pair of data.entries()) {
          if (pair[0] === 'antenne_convention' || pair[0] === 'antenneConvention') {
            antenneConvention = pair[1]
          }
        }

        const response = await ApiService.postRequest('/antenne_convention_sessions', data);
        if (response.status === 201) {
          if(response.data.diplome_session !== undefined && response.data.diplome_session !== null) {
            let form =  {
              diplome_session: response.data.diplome_session,
              antenne_convention: antenneConvention
            }

            await dispatch('addConventionSessionEtudiant', form);
          }
          this.commit('callbackAddSession', response.data);
          Notify.create({
            message: 'La session a été ajoutée avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editAntenneConventionSession({ state, dispatch }, formData) {
      try {
        const response = await ApiService.patchRequest(`${formData.uri}`, formData.data);
        if (response.status === 204) {
          if (formData.diplome_session !== undefined && formData.diplome_session !== null) {
            await dispatch('updateConventionSessionEtudiant', formData);
          }
          this.commit('callbackEditSession', response.data);
          Notify.create({
            message: 'La session a été modifiée avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editStatusConventionSession(context, data) {
      try {
        const response = await ApiService.patchRequest(`${data.uri}`, { etat: data.etat });
        if (response.status === 204) {
          this.commit('callbackEditStatusSession', response.data);
          Notify.create({
            message: data.message,
            color: "green"
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async addConventionSessionEtudiant({ state, dispatch }, data) {
      try {
        const response = await dispatch('fetchEtudiantsByFilter', { diplomeSession: data.diplome_session.id });
        if (response.length > 0) {
          // edition des stagiaires
          response.forEach((value) => {
            // mise en forme des données
            let formData = new FormData();
            formData.append('antenne_convention', data.antenne_convention);
            formData.uri = value['@id'];

            dispatch('editEtudiant', formData);
          });
        } else {
          Notify.create({
            message: "Pas de stagiaire dans cette session.",
            color: "green"
          });
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async updateConventionSessionEtudiant({ state }, data){
      try {
        const response = await ApiService.patchRequest(`${formaData.uri}/stagiaires`, formaData);
        if (response.status === 204) {
          this.commit('callbackEditSession', response.data);
          Notify.create({
            message: 'La session a été modifiée avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },

    /* FRAIS DE FORMATION */
    async convertDataFeesToFormData({ state, dispatch }, data) {
      try {
        let form = new FormData();
        form.append('antenneConvention', data.antenne_convention);
        form.append('type_of_fee', data.type_of_fee);
        form.append('number_of_hours', data.number_of_hours);
        form.append('hourly_cost', data.hourly_cost);

        return await dispatch("addAntenneConventionFee", form, { root: true });
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async addAntenneConventionFee({ state }, data) {
      try {
        const response = await ApiService.postRequest('/antenne_convention_prices', data);
        if (response.status === 201) {
          this.commit('callbackAddFee', response.data);
          Notify.create({
            message: 'Les frais de formation on été ajoutés avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editAntenneConventionFee({ state }, formData) {
      try {
        const response = await ApiService.patchRequest(`${formData.uri}`, formData.data);
        if (response.status === 200) {
          this.commit('callbackEditFee', response.data);
          Notify.create({
            message: 'Les frais de formation ont été modifiés avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editStatusConventionFee(context, data) {
      try {
        const response = await ApiService.patchRequest(`${data.uri}`, { etat: data.etat });
        if (response.status === 204) {
          this.commit('callbackEditStatusFee', response.data);
          Notify.create({
            message: data.message,
            color: "green"
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getConvention(state) {
      return state.convention;
    },
    getConventions(state) {
      return state.conventions;
    },
    getAntenneConventionDocument(state) {
      return state.objet;
    },
    getAntenneConventionSession(state) {
      return state.objet;
    }
  }
}
