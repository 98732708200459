import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const etudiantContrat = {
  state: {
    contrats: [],
    contrat: null
  },
  mutations: {
    setContrats(state, contrats){
      state.contrats = contrats;
    },
    setContrat(state, contrat){
      state.contrat = contrat;
    }
  },
  actions: {
    //Ajoute un contrat à un etudiant
    async addContrat(context, formData){
      try{
        const response = await ApiService.postRequest('etudiant_contrats', formData);
        context.commit('setContrat', response.data);
        Notify.create({
          message:"Le contrat a été enregistré avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout du contrat",
          color: 'red'
        })
      }
    },
    //Modifie le contrat
    async updateContrat(context, data){
      try{
        const response = await ApiService.postRequest(data.url, data.formData);
        context.commit('setContrat', response.data);

        Notify.create({
          message: "Contrat enregistré avec succès",
          color:'green'
        });

      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification du contrat",
          color: 'red'
        })
      }
    },
    // Modification pour une rupture de contrat
    async updateRuptureContrat(context, data){
      try{
        const response  = await ApiService.patchRequest(data.url, data.datas);
        context.commit('setContrat', response.data);
        Notify.create({
          message: "Contrat modifié avec succès",
          color:'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modifiaction du contrat",
          color: 'red'
        })
      }
    }
  },
  getters: {
    getEtudiantContrats(state){
      return state.contrats
    },
    getEtudiantContrat(state){
      return state.contrat
    },
  }
}
