import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

/*Pour les modeles des stagiaires*/
export const modeleDocuments = {
  state: {
    modeleDocument: null,
    modeleDocuments: [],
  },
  mutations: {
    setModeleDocument(state, modeleDocument) {
      state.modeleDocument = modeleDocument;
    },
    setModeleDocuments(state, modeleDocuments) {
      state.modeleDocuments = modeleDocuments;
    },
  },
  actions: {
    async fetchModeleDocumentsBySlug(context, slug) {
      return await ApiService.getRequest(`/modeles?folder.slug=${slug}`).then(
        response => {
          context.commit('setModeleDocuments', response.data['hydra:member']);
        }
      )
        .catch( error => {
          console.error(error)
          Notify.create({
            message: "Une erreur s'est produite.",
            color: "red"
          });
        });
    },
    async downloadModele(context, data){
      try {
        await ApiService.downloadRequest({
          url: data.uri,
          method: 'POST',
          data: {
            etudiants: data.etudiants,
            documents: data.documents,
            format: data.format,
          }
        })
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getModeleDocument(state) {
      return state.modeleDocument;
    },
    getModeleDocuments(state) {
      return state.modeleDocuments;
    }
  }
}
