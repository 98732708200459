const paramCfas = {
  default: {
    id: 1,
    userLogged: ["cfa"],                // l'entité loggé est-elle un cfa ou une antenne ? (["cfa"]/["antenne"]/["cfa","antenne"])
    cfa: {
      id: null                        // identifiant du cfa dans la table cfa (ici null pour les paramètres par default)
    },
    testOnly: false,                      // pour les clients tests (true/false)
    activities: ["autre"],               // secteurs d'activité du cfa
    denominations: {
      menuGestion: 'CFA DEMO',
      menuFormation: 'PÉDAGOGIE',
      antenneField: 'Antenne',
      codeAnalytiqueField: 'Code analytique', // dans stagiaire/facturation
      antennelModuleName: 'Antennes UFA',
      antenneOrDispositif: 'antenne',
    },
    menu: {
      formation: [],                     // droits d'affichage des sous-modules
      facturation: [],
      postformation: [],
      gestion: []
    },
    facturation: {
      typeFactureDetails: [
        'pack_informatique',
        'pack_materiel',
        'pack_informatique_1er_acompte',
        'pack_informatique_2eme_acompte',
        'pack_informatique_3eme_acompte',
        'pack_informatique_4eme_acompte',
        'pack_informatique_5eme_acompte',
        'pack_informatique_6eme_acompte'
      ]
    },
    stagiaire: {
      filtres: [
        ''
      ],
      typeDocumentAdministratifs: [
        'attestation_securite_sociale',
        'attestation_responsabilite_civile',
        'carte_sejour',
        'certificat_jdc',
        'certificat_medical',
        'consentement_rgpd',
        'cni',
        'cni_europe',
        'extrait_casier_judiciaire',
        'fiche_liaison',
        'passeport',
        'rib',
        'validite_pole_emploi',

        'psc1',
        'pse1',
        'documents_diplomes',
        'dossier_inscription',
        'bnssa',
        'attestations_tep',
        'carte_professionnelle',
        'cheque',
        'fiche_signalitique',
        'cv',
        'licence_ffe',
        'attestation_completude_dossier_specifique_ffe',
        'attestation_campus',
        'cerfa_contrat_formation',
        'convention_formation',
        'attestation_entree_formation',
        'droit_image_cfa',
        'droit_image_hors_cfa',
        'reglement_interieur',
        'courrier_admission',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
      ],
      typeDocumentPedagogiques: [
        'attestation_epmsp',
        'attestation_reussite',
        'attestation_reussite_partielle',
        'attestation_sortie',
        'bti_entretiens',
        'emargement_1er_equipement',
        'equivalence',
        'evaluations_formatives',
        'pif',
        'positionnement',
        'fiche_mission',
        'fiche_poste',
        'grille_certification',
        // 'grille_positionnement',
        'grille_selection',
        // 'grille_tep',
        'test_exigences',
        'visites_structure'
      ]
    },
    entretien: {
      interviewsType: [
        'entretiens_entree',
        'entretien_mi_parcours',
        'entretien_fin_parcours',
        'suivi_apprentissage',
        'autre'
      ]
    },
    accessRight: {
      hasSectors: false,                        // l'antenne ou le cfa voit t'il les secteurs de formation (true/false)
      hasAdministrativeBranch: false,           // l'antenne ou le cfa voit t'il l'antenne administrative (true/false)
      hasSessions: false,                       // l'antenne ou le cfa voit'il les sessions (dans sessions /groupes) (true/false)
      hasGroups: false,                         // l'antenne ou le cfa voit'il les groupes (dans sessions /groupes) (true/false)
      typeDiplome: true,                        // l'antenne ou le cfa voit'il le champs type de diplôme (dans diplome) (true/false)
      hasActionsRatingStars: false,             // l'antenne ou le cfa voit'il les évaluations des actions pédagogiques en rating (étoiles)
      hasRepositoriesRatingStars: false,        // l'antenne ou le cfa voit'il les évaluations des actions pédagogiques en rating (étoiles)
      canChangeAntenneInSwitchStudent: false,   // l'antenne ou le cfa voit il le champs antennes (dans basculer stagiaire) (true/false)
      gedFolders: [],
    },
    conventions: {
      haveFeeTypeShortList: false
    },
    // champ json en bdd - ordre des champs dans "formation" du formulaire stagiaire (ou candidature) - ici par blocs
    orderFieldsTrainingForm: {
      type_cursus: "order-3",
      status: "order-4",
      type_status_autre_details: "order-5",
      antenne: "order-0", //1
      antenne_secteur: "order-2",
      antenne_convention: "order-2",
      type_action: "order-6",
      diplome: "order-6",
      module: "order-6",
      diplome_session: "order-7",
      action_session: "order-7",
      antenne_groupe_session: "order-7",
      dt_date_entree: "order-8",
      date_entree_prevue: "order-8",
      date_sortie_prevue: "order-9",
      date_selection_trials: "order-10",
      date_selection_requirement: "order-11",
      date_positioning_start: "order-12",
      date_positioning_end: "order-12",
      dt_date_first_contact: "order-100",
      funding: "order-101",
      funding_details: "order-102",
      formation_hours: "order-103",
      company_hours: "order-104",
      entreprise_tuteur_title: "order-200",
      desired_entreprise: "201",
      desired_tutor: "order-202",
      desired_tutor_lastname: "order-203",
      desired_tutor_firstname: "order-204",
      btn_choose_another_tuteur: "order-205",
      pole_emploi_title: "order-300",
      pole_emploie_id: "order-301",
      pole_emploie_since: "order-302",
      pole_emploie_agency: "order-303",
      pole_emploie_advisor: "order-304",
      pole_emploie_advisor_phone: "order-305",
      formationCustomFields: "order-306",
    }
  },
  // Toutes les antennes de FUTUROSUD
  antennes: {
    id: 2,
    userLogged: ["antenne"],
    cfa: {
      id: 1
    },
    testOnly: false,
    activities: ["sport"],
    denominations: {
      menuGestion: 'Antenne UFA',
      menuFormation: 'FORMATION',
      antenneField: 'Antenne ',
      codeAnalytiqueField: 'Code analytique',
      antennelModuleName: 'Antennes UFA',
      antenneOrDispositif: 'antenne',
    },
    menu: {
      formation: ['francecompetences', 'referentiels', 'actions', 'sequences', 'pif', 'evaluations', 'tests'],
      facturation: [],
      postformation: ['suivis'],
      gestion: ['antenne', 'tableaudebord', 'statistiques', 'gedcollaborative', 'modeles', 'gestionutilisateurs'],
      taxes: []
    },
    accessRight: {
      hasSectors: true,
      hasAdministrativeBranch: true,
      hasSessions: true,
      hasGroups: true,
      typeDiplome: true,
      hasActionsRatingStars: false,
      hasRepositoriesRatingStars: false,
      canChangeAntenneInSwitchStudent: true,
      gedFolders: ['cfa', 'antenne', 'espace_perso', 'pdp'],
      canSeeConventionNumberOnFacturation: true,
    },
    conventions: {
      haveFeeTypeShortList: false
    },
    facturation: {
      typeFactureDetails: [
        'pack_informatique',
        'pack_materiel',
        'pack_informatique_1er_acompte',
        'pack_informatique_2eme_acompte',
        'pack_informatique_3eme_acompte',
        'pack_informatique_4eme_acompte',
        'pack_informatique_5eme_acompte',
        'pack_informatique_6eme_acompte'
      ]
    },
    stagiaire: {
      filtres: [
        ''
      ],
      typeDocumentAdministratifs: [
        'attestation_securite_sociale',
        'attestation_responsabilite_civile',
        'carte_sejour',
        'certificat_jdc',
        'certificat_medical',
        'consentement_rgpd',
        'cni',
        'cni_europe',
        'extrait_casier_judiciaire',
        'fiche_liaison',
        'passeport',
        'rib',
        'validite_pole_emploi',

        'psc1',
        'pse1',
        'documents_diplomes',
        'dossier_inscription',
        'bnssa',
        'attestations_tep',
        'carte_professionnelle',
        'cheque',
        'fiche_signalitique',
        'cv',
        'licence_ffe',
        'attestation_completude_dossier_specifique_ffe',
        'attestation_campus',
        'cerfa_contrat_formation',
        'convention_formation',
        'attestation_entree_formation',
        'droit_image_cfa',
        'droit_image_hors_cfa',
        'reglement_interieur',
        'courrier_admission',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
      ],
      typeDocumentPedagogiques: [
        'non_renseigne',
        'attestation_epmsp',
        'attestation_reussite',
        'attestation_reussite_partielle',
        'attestation_sortie',
        'bti_entretiens',
        'emargement_1er_equipement',
        'equivalence',
        'evaluations_formatives',
        'pif',
        'positionnement',
        'fiche_mission',
        'fiche_poste',
        'grille_certification',
        // 'grille_positionnement',
        'grille_selection',
        // 'grille_tep',
        'test_exigences',
        'visites_structure'
      ]
    },
    entretien: {
      interviewsType: [
        'entretiens_entree',
        'entretien_mi_parcours',
        'entretien_fin_parcours',
        'suivi_apprentissage',
        'autre'
      ]
    },
    // champ json en bdd
    orderFieldsTrainingForm: {
      type_cursus: "order-0",
      antenne_convention: "order-1",
      status: "order-2",
      type_status_autre_details: "order-3",
      antenne: "order-4",
      antenne_secteur: "order-5",
      diplome: "order-6",
      diplome_session: "order-7",
      module: "order-8",
      antenne_groupe_session: "order-7",
      dt_date_entree: "order-8",
      date_entree_prevue: "order-8",
      date_sortie_prevue: "order-9",
      date_selection_trials: "order-10",
      date_selection_requirement: "order-11",
      date_positioning_start: "order-12",
      date_positioning_end: "order-12",
      dt_date_first_contact: "order-100",
      funding: "order-101",
      funding_details: "order-102",
      formation_hours: "order-103",
      company_hours: "order-104",
      entreprise_tuteur_title: "order-200",
      desired_entreprise: "201",
      desired_tutor: "order-202",
      desired_tutor_lastname: "order-203",
      desired_tutor_firstname: "order-204",
      btn_choose_another_tuteur: "order-205",
      pole_emploi_title: "order-300",
      pole_emploie_id: "order-301",
      pole_emploie_since: "order-302",
      pole_emploie_agency: "order-303",
      pole_emploie_advisor: "order-304",
      pole_emploie_advisor_phone: "order-305",
      formationCustomFields: "order-306",
    }
  },
  futurosud: {
    id: 3,
    userLogged: ["cfa"],
    cfa: {
      id: 1
    },
    testOnly: false,
    activities: ["sport"],
    denominations: {
      menuGestion: 'CFA FUTUROSUD',
      menuFormation: 'FORMATION',
      antenneField: 'Antenne de déroulement ',
      codeAnalytiqueField: 'Code analytique',
      antennelModuleName: 'Antennes UFA',
      antenneOrDispositif: 'antenne',
    },
    menu: {
      formation: ['francecompetences', 'referentiels', 'actions', 'sequences', 'pif', 'evaluations', 'tests'],
      facturation: ['aidesmobilises', 'factures'],
      postformation: ['enquetesatisfaction', 'questionnaireinsertion'],
      gestion: ['parametres', 'tableaudebord', 'antenne', 'diplomes', 'statistiques', 'gedcollaborative', 'modeles', 'gestionutilisateurs'],
      taxes: ['all_taxes']
    },
    accessRight: {
      hasSectors: true,
      hasAdministrativeBranch: false,
      hasSessions: false,
      hasGroups: false,
      typeDiplome: true,
      hasActionsRatingStars: false,
      hasRepositoriesRatingStars: false,
      canChangeAntenneInSwitchStudent: true,
      gedFolders: ['cfa', 'siège', 'espace_perso', 'pdp'],
      canSeeConventionNumberOnFacturation: true,
    },
    conventions: {
      haveFeeTypeShortList: false
    },
    facturation: {
      typeFactureDetails: [
        'pack_informatique',
        'pack_materiel',
        'pack_informatique_1er_acompte',
        'pack_informatique_2eme_acompte',
        'pack_informatique_3eme_acompte',
        'pack_informatique_4eme_acompte',
        'pack_informatique_5eme_acompte',
        'pack_informatique_6eme_acompte'
      ]
    },
    stagiaire: {
      filtres: [
        'antennes'
      ],
      typeDocumentAdministratifs: [
        'attestation_securite_sociale',
        'attestation_responsabilite_civile',
        'carte_sejour',
        'certificat_jdc',
        'certificat_medical',
        'consentement_rgpd',
        'cni',
        'cni_europe',
        'extrait_casier_judiciaire',
        'fiche_liaison',
        'passeport',
        'rib',
        'validite_pole_emploi',

        'psc1',
        'pse1',
        'documents_diplomes',
        'dossier_inscription',
        'bnssa',
        'attestations_tep',
        'carte_professionnelle',
        'cheque',
        'fiche_signalitique',
        'cv',
        'licence_ffe',
        'attestation_completude_dossier_specifique_ffe',
        'attestation_campus',
        'cerfa_contrat_formation',
        'convention_formation',
        'attestation_entree_formation',
        'droit_image_cfa',
        'droit_image_hors_cfa',
        'reglement_interieur',
        'courrier_admission',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
      ],
      typeDocumentPedagogiques: [
        'non_renseigne',
        'attestation_epmsp',
        'attestation_reussite',
        'attestation_reussite_partielle',
        'attestation_sortie',
        'bti_entretiens',
        'emargement_1er_equipement',
        'equivalence',
        'evaluations_formatives',
        'pif',
        'positionnement',
        'fiche_mission',
        'fiche_poste',
        'grille_certification',
        // 'grille_positionnement',
        'grille_selection',
        // 'grille_tep',
        'test_exigences',
        'visites_structure'
      ]
    },
    entretien: {
      interviewsType: [
        'entretiens_entree',
        'entretien_mi_parcours',
        'entretien_fin_parcours',
        'suivi_apprentissage',
        'autre'
      ]
    },
    // champ json en bdd
    orderFieldsTrainingForm: {
      type_cursus: "order-3",
      status: "order-4",
      type_status_autre_details: "order-5",
      antenne: "order-0",
      antenne_secteur: "order-2",
      antenne_convention: "order-2",
      type_action: "order-6",
      diplome: "order-6",
      module: "order-6",
      diplome_session: "order-7",
      action_session: "order-7",
      antenne_groupe_session: "order-7",
      dt_date_entree: "order-8",
      date_entree_prevue: "order-8",
      date_sortie_prevue: "order-9",
      date_selection_trials: "order-10",
      date_selection_requirement: "order-11",
      date_positioning_start: "order-12",
      date_positioning_end: "order-12",
      dt_date_first_contact: "order-100",
      funding: "order-101",
      funding_details: "order-102",
      formation_hours: "order-103",
      company_hours: "order-104",
      entreprise_tuteur_title: "order-200",
      desired_entreprise: "201",
      desired_tutor: "order-202",
      desired_tutor_lastname: "order-203",
      desired_tutor_firstname: "order-204",
      btn_choose_another_tuteur: "order-205",
      pole_emploi_title: "order-300",
      pole_emploie_id: "order-301",
      pole_emploie_since: "order-302",
      pole_emploie_agency: "order-303",
      pole_emploie_advisor: "order-304",
      pole_emploie_advisor_phone: "order-305",
      formationCustomFields: "order-306",
    }
  },
  guyhoquet: {
    id: 4,
    userLogged:  ["cfa", "antenne"],
    cfa: {
      id: 13
    },
    testOnly: false,
    activities: ["immobilier"],
    denominations: {
      menuGestion: 'GUY HOQUET',
      menuFormation: 'PÉDAGOGIE',
      antenneField: 'Campus souhaité',
      codeAnalytiqueField: 'Code agence',
      antennelModuleName: 'Antennes UFA',
      antenneOrDispositif: 'antenne',
    },
    menu: {
      formation: ['francecompetences', 'referentiels', 'actions', 'sequences', 'pif', 'evaluations', 'tests'],
      facturation: ['aidesmobilises', 'factures'],
      postformation: ['enquetesatisfaction', 'questionnaireinsertion'],
      gestion: ['parametres', 'tableaudebord', 'antenne', 'statistiques', 'gedcollaborative', 'modeles', 'gestionutilisateurs'],
      taxes: []

    },
    accessRight: {
      hasSectors: true,
      hasAdministrativeBranch: false,
      hasSessions: false,
      hasGroups: true,
      typeDiplome: false,
      hasActionsRatingStars: false,
      hasRepositoriesRatingStars: false,
      canChangeAntenneInSwitchStudent: false,
      gedFolders: ['cfa', 'antenne', 'espace_perso', 'pdp'],
      canSeeConventionNumberOnFacturation: false,
    },
    conventions: {
      haveFeeTypeShortList: true
    },
    facturation: {
      typeFactureDetails: [
        'pack_informatique_1er_acompte',
        'pack_informatique_2eme_acompte',
        'pack_informatique_3eme_acompte',
        'pack_informatique_4eme_acompte',
        'pack_informatique_5eme_acompte',
        'pack_informatique_6eme_acompte',
        'solde-avoir'
      ]
    },
    stagiaire: {
      filtres: [
        ''
      ],
      typeDocumentAdministratifs: [
        'attestation_securite_sociale',
        'attestation_responsabilite_civile',
        'carte_sejour',
        'certificat_jdc',
        'certificat_medical',
        'consentement_rgpd',
        'cni',
        'cni_europe',
        'extrait_casier_judiciaire',
        'fiche_liaison',
        'passeport',
        'rib',
        'validite_pole_emploi',

        'psc1',
        'pse1',
        'documents_diplomes',
        'dossier_inscription',
        'bnssa',
        'attestations_tep',
        'carte_professionnelle',
        'cheque',
        'fiche_signalitique',
        'cv',
        'licence_ffe',
        'attestation_completude_dossier_specifique_ffe',
        'attestation_campus',
        'cerfa_contrat_formation',
        'convention_formation',
        'attestation_entree_formation',
        'droit_image_cfa',
        'droit_image_hors_cfa',
        'reglement_interieur',
        'courrier_admission',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
        'attestation_securite_sociale',
      ],
      typeDocumentPedagogiques: [
        'attestation_assiduite_formation',
        'attestation_fin_formation',
        'bulletin_1er_semestre_1_annee',
        'bulletin_2eme_semestre_1_annee',
        'bulletin_1er_semestre_2_annee',
        'bulletin_2eme_semestre_2_annee',
        'certificat_scolarite_1_annee',
        'certificat_scolarite_2_annee',
        'certificat_realisation_1/4',
        'certificat_realisation_2/4',
        //'certificat_realisation_3/4',
        //'certificat_realisation_4/4',
        'dossier_candidature',
        'dossier_cpap',
        'positionnement',
        'premier_bts_blanc',
        'questionnaire_peda_1_1_annee',
        'questionnaire_peda_2_1_annee',
        'questionnaire_peda_1_2_annee',
        'questionnaire_peda_2_2_annee',
        'releve_de_notes_bts',
        'second_bts_blanc',
        'suivi_entreprise_1',
        'suivi_entreprise_2',
      ]
    },
    entretien: {
      interviewsType: [
        'entretiens_entree',
        'entretien_mi_parcours',
        'entretien_fin_parcours',
        'referent_ecole',
        'suivi_apprentissage',
        'autre'
      ]
    },
    // champ json en bdd
    orderFieldsTrainingForm: {
      type_cursus: "order-0",
      status: "order-0",
      type_status_autre_details: "order-0",
      antenne: "order-1",
      antenne_secteur: "order-2",
      antenne_convention: "order-2",
      type_action: "order-3",
      diplome: "order-3",
      module: "order-3",
      diplome_session: "order-4",
      action_session: "order-4",
      antenne_groupe_session: "order-4",
      dt_date_entree: "order-8",
      date_entree_prevue: "order-8",
      date_sortie_prevue: "order-9",
      date_selection_trials: "order-10",
      date_selection_requirement: "order-11",
      date_positioning_start: "order-12",
      date_positioning_end: "order-12",
      dt_date_first_contact: "order-100",
      funding: "order-101",
      funding_details: "order-102",
      formation_hours: "order-103",
      company_hours: "order-104",
      entreprise_tuteur_title: "order-200",
      desired_entreprise: "201",
      desired_tutor: "order-202",
      desired_tutor_lastname: "order-203",
      desired_tutor_firstname: "order-204",
      btn_choose_another_tuteur: "order-205",
      pole_emploi_title: "order-300",
      pole_emploie_id: "order-301",
      pole_emploie_since: "order-302",
      pole_emploie_agency: "order-303",
      pole_emploie_advisor: "order-304",
      pole_emploie_advisor_phone: "order-305",
      formationCustomFields: "order-306",
    }
  },
  afept: {
    id: 6,
    userLogged: ["cfa", "antenne"],
    cfa: {
      id: 20
    },
    testOnly: true,
    activities: ["multi"],
    denominations: {
      menuGestion: 'AFEPT',
      menuFormation: 'PÉDAGOGIE',
      antenneField: 'Dispositif de formation',
      codeAnalytiqueField: 'Code analytique',
      antennelModuleName: 'Dispositifs de formation',
      antenneOrDispositif: 'dispositif',
    },
    menu: {
      formation: ['francecompetences', 'referentiels', 'actions', 'sequences', 'pif', 'evaluations', 'tests'],
      facturation: ['aidesmobilises', 'factures'],
      postformation: ['suivis', 'enquetesatisfaction', 'questionnaireinsertion'],
      gestion: ['parametres', 'tableaudebord', 'antenne', 'statistiques', 'gedcollaborative', 'modeles', 'gestionutilisateurs'],
      taxes: []
    },
    accessRight: {
      hasSectors: true,
      hasAdministrativeBranch: false,
      hasSessions: true,
      hasGroups: true,
      typeDiplome: true,
      hasActionsRatingStars: true,
      hasRepositoriesRatingStars: false,
      canChangeAntenneInSwitchStudent: false,
      gedFolders: ['cfa', 'antenne', 'espace_perso', 'pdp'],
      canSeeConventionNumberOnFacturation: true,
    },
    conventions: {
      haveFeeTypeShortList: true
    },
    facturation: {
      typeFactureDetails: [
        'pack_informatique',
        'pack_materiel',
        'pack_informatique_1er_acompte',
        'pack_informatique_2eme_acompte',
        'pack_informatique_3eme_acompte',
        'pack_informatique_4eme_acompte',
        'pack_informatique_5eme_acompte',
        'pack_informatique_6eme_acompte'
      ]
    },
    stagiaire: {
      filtres: [
        ''
      ],
      typeDocumentAdministratifs: [
        'attestation_cpam',
        'attestation_entree_formation',
        'attestation_fin_formation',
        'attestation_inscription_formation',
        'attestation_prise_connaissance',
        'attestation_rqth',
        'autorisation_travail',
        'avis_situation_ft',
        'bulletins_salaire',
        'carte_sejour',
        'cni',
        'consentement_rgpd',
        'contrat_formation',
        'convention_pret_chromebook',
        'convention_stage',
        'droit_image',
        'fiche_prescription',
        'notification_rejet_are_france_travail_moins_3_mois',
        'reglement_interieur',
        'rib',
        'rs1_cerfa_remu',
      ],
      typeDocumentPedagogiques: [
        'non_renseigne',
        'attestation_epmsp',
        'bilan_fin_parcours',
        'bti_entretiens',
        'contrat_pedagogique',
        'evaluations_formatives',
        'positionnement',
        'fiche_mission',
        'grille_certification',
        'livret_suivi_individuel_parcours',
        'test_exigences',
        'visites_structure',
      ]
    },
    entretien: {
      interviewsType: [
        'accompagnement_sante_social',
        'bilan_intermediaire',
        'contractualisation_engagement',
        'entretien_accueil',
        'entretien_ecoute',
        'entretien_bilan_fin_parcours',
        'entretien_referent_handicap',
        'entretien_regulation',
        'entretien_restitution_positionnement',
        'entretien_suivi_pedagogique',
        'entretien_suivi_projet_professionnel',
        'positionnement',
        'signalement'
      ]
    },
    // champ json en bdd
    orderFieldsTrainingForm: {
      type_cursus: "order-3",
      status: "order-4",
      type_status_autre_details: "order-5",
      antenne: "order-0",
      antenne_secteur: "order-2",
      antenne_convention: "order-2",
      type_action: "order-6",
      diplome: "order-6",
      module: "order-6",
      diplome_session: "order-7",
      action_session: "order-7",
      antenne_groupe_session: "order-7",
      dt_date_entree: "order-8",
      date_entree_prevue: "order-8",
      date_sortie_prevue: "order-9",
      date_selection_trials: "order-10",
      date_selection_requirement: "order-11",
      date_positioning_start: "order-12",
      date_positioning_end: "order-12",
      dt_date_first_contact: "order-100",
      funding: "order-101",
      funding_details: "order-102",
      formation_hours: "order-103",
      company_hours: "order-104",
      entreprise_tuteur_title: "order-200",
      desired_entreprise: "201",
      desired_tutor: "order-202",
      desired_tutor_lastname: "order-203",
      desired_tutor_firstname: "order-204",
      btn_choose_another_tuteur: "order-205",
      pole_emploi_title: "order-300",
      pole_emploie_id: "order-301",
      pole_emploie_since: "order-302",
      pole_emploie_agency: "order-303",
      pole_emploie_advisor: "order-304",
      pole_emploie_advisor_phone: "order-305",
      formationCustomFields: "order-306",
    }
  },
  usap: {
    id: 7,
    userLogged: ["cfa", "antenne"],
    cfa: {
      id: 30
    },
    testOnly: true,
    activities: ["sport"],
    denominations: {
      menuGestion: 'USAP FORMATION',
      menuFormation: 'PÉDAGOGIE',
      antenneField: 'Antenne',
      codeAnalytiqueField: 'Code analytique',
      antennelModuleName: 'Antennes UFA',
      antenneOrDispositif: 'antenne',
    },
    menu: {
      formation: ['francecompetences', 'referentiels', 'actions', 'sequences', 'pif', 'evaluations', 'tests'],
      facturation: ['aidesmobilises', 'factures'],
      postformation: ['EnqueteDebutFormation', 'EnqueteMilieuFormation', 'EnqueteFinFormation', 'EnqueteFormateur',  'questionnaireinsertion'],
      gestion: ['parametres', 'tableaudebord', 'antenne', 'statistiques', 'gedcollaborative', 'modeles', 'gestionutilisateurs'],
      taxes: ['all_taxes']
    },
    accessRight: {
      hasSectors: true,
      hasAdministrativeBranch: false,
      hasSessions: true,
      hasGroups: true,
      typeDiplome: true,
      hasActionsRatingStars: false,
      hasRepositoriesRatingStars: false,
      canChangeAntenneInSwitchStudent: false,
      gedFolders: ['cfa', 'serveur', 'ecole_privee_usap', 'espace_perso', 'pdp'],
      canSeeConventionNumberOnFacturation: true,
    },
    conventions: {
      haveFeeTypeShortList: true
    },
    facturation: {
      typeFactureDetails: [
        'pack_informatique',
        'pack_materiel',
        'pack_informatique_1er_acompte',
        'pack_informatique_2eme_acompte',
        'pack_informatique_3eme_acompte',
        'pack_informatique_4eme_acompte',
        'pack_informatique_5eme_acompte',
        'pack_informatique_6eme_acompte'
      ]
    },
    stagiaire: {
      filtres: [
        ''
      ],
      typeDocumentAdministratifs: [
        'cni',
        'passeport',
        'carte_vitale',
        'carte_sejour',
        'certificat_medical',
        'attestation_400m',
        'attestation_rugby',
        'dossier_inscription',
        'releve_notes',
        'bulletins',
        'documents_diplomes',
        'psc1',
        'pse1',
        'bnssa',
        'paiement_cvec',
        'cv',
        'autres_documents',
      ],
      typeDocumentPedagogiques: [
        'non_renseigne',
        'attestation_epmsp',
        'bti_entretiens',
        'evaluations_formatives',
        'positionnement',
        'fiche_mission',
        'grille_certification',
        'test_exigences',
        'visites_structure'
      ]
    },
    entretien: {
      interviewsType: [
        'suivi_formation',
        'suivi_structure',
        'autre'
      ]
    },
    // champ json en bdd
    orderFieldsTrainingForm: {
      type_cursus: "order-3",
      status: "order-4",
      type_status_autre_details: "order-5",
      antenne: "order-0",
      antenne_secteur: "order-2",
      antenne_convention: "order-2",
      type_action: "order-6",
      diplome: "order-6",
      module: "order-6",
      diplome_session: "order-7",
      action_session: "order-7",
      antenne_groupe_session: "order-7",
      dt_date_entree: "order-8",
      date_entree_prevue: "order-8",
      date_sortie_prevue: "order-9",
      date_selection_trials: "order-10",
      date_selection_requirement: "order-11",
      date_positioning_start: "order-12",
      date_positioning_end: "order-12",
      dt_date_first_contact: "order-100",
      funding: "order-101",
      funding_details: "order-102",
      formation_hours: "order-103",
      company_hours: "order-104",
      entreprise_tuteur_title: "order-200",
      desired_entreprise: "201",
      desired_tutor: "order-202",
      desired_tutor_lastname: "order-203",
      desired_tutor_firstname: "order-204",
      btn_choose_another_tuteur: "order-205",
      pole_emploi_title: "order-300",
      pole_emploie_id: "order-301",
      pole_emploie_since: "order-302",
      pole_emploie_agency: "order-303",
      pole_emploie_advisor: "order-304",
      pole_emploie_advisor_phone: "order-305",
      formationCustomFields: "order-306",
    }
  },
}
export const parametrageCfas = {
  namespaced: false,
  state: {
    paramCfas: []
  },
  mutations: {
    setParamCfas(state, paramCfas) {
      state.paramCfas = Object.values(paramCfas);
    }
  },
  actions: {
    fetchParamCfas(context) {
      return context.commit('setParamCfas', paramCfas);
    }
  },
  getters: {
    getParamCfas(state) {
      return state.paramCfas;
    },
    getCfaParams: (state) => (userCfaId, userCheckStatus) => {
      if (userCheckStatus) {
        return state.paramCfas.find(cfaParams => {
          return cfaParams.cfa.id === userCfaId && cfaParams.userLogged.includes('cfa');
        });
      } else {
        return state.paramCfas.find(cfaParams => {
          return cfaParams.cfa.id === userCfaId && cfaParams.userLogged.includes('antenne');
        });
      }
    }
  },
}
