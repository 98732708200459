import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const etudiantGrilleMotivation = {
  state: {
    grilles: [],
    grille: null,
  },
  mutations:{
    setGrilles(state, grilles){
      state.grilles = grilles;
    },
    setGrille(state, grille) {
      state.grille = grille;
    },
    callbackEditGrid(state, payload)
    {
      state.objet = payload.objet;
    },
    callbackRemoveGrid(state, grilleId)
    {
      for(let grille in state.grilles){
        if(state.grilles[grille].id == grille){
          state.grilles.splice(grille, 1);
        }
      }
    },
  },
  actions: {
    async fetchGridById(context, url) {
      return await ApiService.getRequest(url).then(
        response => {
          context.commit('setGrilles', response.data)
        }
      ) .catch( error => { console.error(error) } )
    },

    async addGrid(context, data){
      try {
        const response = await ApiService.postRequest('/etudiant_grille_motivations', data);
        context.commit('setGrille', response.data);

        Notify.create({
          message: "La grille a été ajouter avec succès",
          color: "green"
        })
      }
      catch (error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout d'une grille.",
          color: "red"
        })
      }
    },
    async editGrid(context, data){
      try{
        const response = await ApiService.patchRequest(data.url, data.formData);
        console.log('coucou d\'origine', response.data)
        context.commit('setGrille', response.data)

        Notify.create({
          message:"La grille a été modifier avec succès",
          color: 'green'
        })
      }catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la modification de la grille",
          color: 'red'
        })
      }
    },
    async removeGrid(context, url){
      try{
        const response = await ApiService.deleteRequest(url);
        context.commit('callbackRemoveGrid', response.data)
        Notify.create({
          message: "La grille a été supprimer avec succès",
          color: "green"
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la suppression de la grille",
          color: "red"
        })
      }
    }
  },
  getters: {
    getGrilles(state)
    {
      return state.grilles;
    },
    getGrille(state)
    {
      return state.grille;
    }
  },
}
